import * as types from './types'
import { processRecords, processData } from '@/utils'
import i18n from '@/plugins/i18n'

export default {
  INIT ({ commit, firebase }) {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }, pagination) {
    console.log(pagination.page)
    // TODO Adicionar paginação
    const page = rootGetters['bankSlips/page']
    const startAt = rootGetters['bankSlips/startAt']
    const startAfter = rootGetters['bankSlips/startAfter']
    const currentCompany = rootGetters['currentCompany/id']
    const db = firebase.firestore()

    const pageSize = 13
    const companyRef = db.collection('companies').doc(currentCompany)
    let list = db.collection('bankSlips').where('company', '==', companyRef).orderBy('insertedAt', 'desc')

    if (page < pagination.page) {
      console.log('subir')
      list = list.startAfter(startAfter)
    }

    if (page > pagination.page) {
      console.log('descer')
      // list = list.startA(startAt)
      list = list.endAt(startAfter)
    }

    list.limit(pageSize).onSnapshot(async documentSnapshots => {
      // const firstVisible = documentSnapshots.docs[0]
      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1]
      const records = await processRecords(documentSnapshots)

      commit('setPage', pagination.page)
      // commit('setStartAt', firstVisible)
      commit('setStartAfter', lastVisible)
      commit('setList', records)
      commit('setListLoading', false)
      commit('setListRender', true)
    })
  },
  async [types.GET_LIST_BY_STATE] ({ commit, firebase, rootGetters }, state) {
    const currentCompany = rootGetters['currentCompany/id']
    const db = firebase.firestore()
    const companyRef = db.collection('companies').doc(currentCompany)

    db
      .collection('bankSlips')
      .where('company', '==', companyRef)
      .where('state', '==', state)
      .orderBy('insertedAt', 'desc')
      .limit(20)
      .onSnapshot(async documentSnapshots => {
        const records = await processRecords(documentSnapshots)

        records.map(item => {
          item.dueAt = i18n.d(item.dueAt.toDate(), 'date')
          return item
        })

        commit('setList', records)
        commit('setListLoading', false)
        commit('setListRender', true)
      })
  },
  async [types.GET] ({ commit, firebase, rootGetters }, id) {
    const db = firebase.firestore()

    return db
      .collection('bankSlips')
      .doc(id)
      .get()
      .then(processData)
  }
}
