import * as types from './types'
import { apiHost, processData, processRecords, request, dateDiffInDays } from '@/utils'
import i18n from '@/plugins/i18n'
import Query from '@/utils/query'

const getFirstActive = async (db, userRef, state) =>{
  return getFirstActiveBy(db, 'user', userRef, state)
}

const getFirstActiveBy = async (db, relation, value, state) => {
  const records = await db
                          .collection('proposals')
                          .where('state', 'in', state)
                          .where(relation, '==', value)
                          .orderBy('insertedAt', 'desc')
                          .limit(1)
                          .get()
  if (!records.empty) {
    const proposalSnapshot = await records.docs[0].ref.get()
    return processData(proposalSnapshot)
  }
}

const getActive = (db, userId, state) =>{
  return new Promise(resolve => {
    db
      .collection('proposals')
      .where('state', '==', state)
      .where('userPermissions', 'array-contains', userId)
      .orderBy('insertedAt', 'desc')
      .limit(1)
      .onSnapshot(async documentSnapshots => {
        resolve(await processRecords(documentSnapshots))
      })
  })
}

const getAcceptedTerms = (db, proposalId) =>{
  return new Promise(resolve => {
    const proposalRef = db.collection('proposals').doc(proposalId)
    db.collection('terms')
      .where('state', '==', 'accepted')
      .where('proposal', '==', proposalRef)
      .orderBy('insertedAt', 'desc')
      .limit(1)
      .onSnapshot(async documentSnapshots => {
        resolve(await processRecords(documentSnapshots))
      })
  })
}

const getAcceptedByCompany = (db, userRef, companyRef) =>{
  return new Promise(resolve => {
    db
      .collection('proposals')
      .where('state', '==', 'accepted')
      .where('company', '==', companyRef)
      .where('user', '==', userRef)
      .orderBy('insertedAt', 'desc')
      .limit(1)
      .onSnapshot(async documentSnapshots => {
        const records = await processRecords(documentSnapshots)
        resolve(records[0])
      })
  })
}

const getTickets = (db, companyRef, userRef, proposalRef) =>{
  return new Promise(resolve => {
    db
      .collection('bankSlips')
      .where('state', '==', 'registered')
      .where('company', '==', companyRef)
      .where('user', '==', userRef)
      .where('proposal', '==', proposalRef)
      .onSnapshot(async documentSnapshots => {
        resolve(documentSnapshots.size > 0)
      })
  })
}

export default {
  INIT ({}) {},
  async [types.GET_PROPOSAL] ({ commit, firebase, rootGetters }, {proposalId, recalculate}) {
    commit('app/setLoading', true, { root: true })
    const db = firebase.firestore()
    const userId = rootGetters['user/getUserInfo']['userId']
    const userRef = db.collection('users').doc(userId)

    if(recalculate){
      await firebase.functions().httpsCallable('proposalRecalculate')({ proposalId })
    }

    db
      .collection('proposals')
      .doc(proposalId)
      .onSnapshot(async doc => {
        const proposal = await processData(doc)
        const company = await processData(await proposal.company)
        const companyRef = db.collection('companies').doc(company.id)
        const proposalAccepted = await getAcceptedByCompany(db, userRef, companyRef)
        const proposalRef = proposalAccepted && db.collection('proposals').doc(proposalAccepted.id)
        const hasBankSlips = proposalAccepted && await getTickets(db, companyRef, userRef, proposalRef)
        const caseRecord = await processData(await proposal.case)
        const bankSlipsSnapshots = await db.collection('bankSlips').where('proposal', '==', doc.ref).get()
        const ticketsPayments = await processRecords(bankSlipsSnapshots)
        ticketsPayments.sort((a, b) => a.dueAt.seconds - b.dueAt.seconds)

        // proposal.hasProposalAccepted = proposalAccepted && hasBankSlips
        proposal.hasProposalAccepted = false
        proposal.case = caseRecord
        proposal.company = company
        proposal.ticketsPayments = ticketsPayments

        commit('setProposal', proposal)
        commit('app/setLoading', false, { root: true })
      })
  },
  async [types.ACCEPT] ({ commit, firebase }, { proposalId, termEmail }) {
    commit('app/setLoading', true, { root: true })

    return firebase.functions().httpsCallable('proposalAccept')({ proposalId, termEmail }).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  async [types.REFUSE] ({ commit, firebase }, data) {
    commit('app/setLoading', true, { root: true })

    return firebase.functions().httpsCallable('proposalRefuse')(data).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  async [types.NEGOTIATE] ({ commit, firebase }, data) {
    commit('app/setLoading', true, { root: true })

    return firebase.functions().httpsCallable('proposalNegotiate')(data).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  async [types.NEGOTIATION_ACCEPT] ({ commit, firebase }, proposalId) {
    commit('app/setLoading', true, { root: true })

    return firebase.functions().httpsCallable('proposalNegotiationAccept')({ proposalId }).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  async [types.NEGOTIATION_REFUSE] ({ commit, firebase }, data) {
    commit('app/setLoading', true, { root: true })

    return firebase.functions().httpsCallable('proposalNegotiationRefuse')(data).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  async [types.FIRST_ACTIVE] ({ commit, firebase, rootGetters }) {
    const db = firebase.firestore()
    const userId = rootGetters['user/getUserInfo']['userId']
    const userRef = db.collection('users').doc(userId)
    const proposal = await getFirstActive(db, userRef, [ 'created', 'visualized'])
    commit('firstActive', proposal)
    commit('app/setLoading', false, { root: true })
    return proposal
  },
  async [types.FIRST_ACTIVE_FOR_CASE] ({ commit, firebase }, id) {
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(id)
    const proposal = await getFirstActiveBy(db, 'case', caseRef, [ 'created', 'visualized' ])
    commit('firstActiveForCase', proposal)
    commit('app/setLoading', false, { root: true })
    return proposal
  },
  async [types.LAST_FOR_CASE] ({ commit, firebase, rootGetters }, id) {
    const db = firebase.firestore()
    const caseId = rootGetters['cases/record'] && rootGetters['cases/record']['id'] || id
    const caseRef = db.collection('cases').doc(caseId)
    db
      .collection('proposals')
      .where('case', '==', caseRef)
      .limit(1)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async documentSnapshots => {
        const records = await processRecords(documentSnapshots)
        const proposal = documentSnapshots.empty ? {} : records[0]

        commit('lastForCase', proposal)
        commit('app/setLoading', false, { root: true })
      })
  },
  async [types.GET_LIST] ({ commit, firebase, rootGetters }, paginate) {
    const db = firebase.firestore()
    const currentCompany = rootGetters['currentCompany/id']
    const userId = rootGetters['user/getUserInfo']['userId']
    const pageSize = paginate.rowsPerPage

    let stats = await db.collection('companies').doc(currentCompany).collection('stats').doc('--cases--').get()
    let baseQuery = db.collection('proposals').where('userPermissions', 'array-contains', userId).orderBy('updatedAt', 'desc')

    const query = new Query(baseQuery, commit)
    baseQuery = query.filterByState(paginate.state)
    baseQuery = await query.pageHas(1, paginate.page)
    baseQuery = query.pageNext(paginate.page)
    baseQuery = query.pagePrev(paginate.page)
    baseQuery = baseQuery.limit(pageSize)

    baseQuery.onSnapshot(async snapshots => {
      const length = stats.data()[paginate.state]
      let records = await processRecords(snapshots)

      records.length && query.paginator.setCurrentPage(paginate.page)
      records.length && query.paginator.setPage(paginate.page, snapshots.docs[0], snapshots.docs[snapshots.docs.length - 1])

      if (paginate.state === 'accepted') {
        records.map(async record => {
          if (record.term && record.term.get) {
            const termSnapshot = await record.term.get()
            const termData = termSnapshot.data()
            record.termFile = termData.file
          }
        })
      }

      records.map(item => {
        item.updatedAt = i18n.d(item.updatedAt.toDate(), 'short_datetime')
        return item
      })

      commit('setLength', length)
      commit('setList', records)
      commit('setListLoading', false)
      commit('setListRender', true)
    })
  },
  async [types.GET_ACTIVE_LIST] ({ commit, firebase, rootGetters }) {
    const db = firebase.firestore()
    const userId = rootGetters['user/getUserInfo']['userId']
    const createdP = getActive(db, userId, 'created')
    const visualizedP = getActive(db, userId, 'visualized')

    Promise.all([createdP, visualizedP]).then(values => {
      const [a, b] = values
      const records = Array.from(new Set([...a, ...b]))

      commit('setList', records)
      commit('setListLoading', false)
      commit('setListRender', true)
    })
  },
  async [types.GET_LIST_BY_CASE] ({ commit, firebase }, id) {
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(id)

    db
      .collection('proposals')
      .where('case', '==', caseRef)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async documentSnapshots => {
        let records = await processRecords(documentSnapshots)

        records = records.map(item => {
          item.daysExpire = dateDiffInDays(new Date(), item.expireAt.toDate())
          return item
        })

        commit('setListByCase', records)
        commit('setListByCaseLoading', false)
      })
  },
  async [types.GET_BANK_SLIPS] ({ commit, firebase, rootGetters }, id) {
    const currentCompany = rootGetters['currentCompany/id']
    const db = firebase.firestore()
    const proposalRef = db.collection('proposals').doc(id)

    db
      .collection(`companies/${currentCompany}/bankSlips`)
      .where('proposal', '==', proposalRef)
      .orderBy('dueAt', 'desc')
      .onSnapshot(async documentSnapshots => {
        const records = await processRecords(documentSnapshots)

        records.map(item => {
          item.dueAt = i18n.d(item.dueAt.toDate(), 'date')
          return item
        })

        commit('setList', records)
      })
  },
  [types.GET_SIMULATED_RENEGOTIATION] ({ commit }, {id, parcel}) {
    // TODO Pegar renegotiation id, parcel
    // commit(types.GET_SIMULATED_RENEGOTIATION, response.data.simulated_renegotiation)
  },
  [types.PROPOSAL_CHANGE_PLOT] ({ firebase, state }, {id, plot}) {
    const installmentSelected = state.record.installments[plot - 1]

    firebase.firestore().collection('proposals').doc(id).update({ installmentSelected })
  },
  [types.DIRECT_NEGOTIATION_ACCEPT] ({ commit, firebase }, { proposalId }) {
    commit('app/setLoading', true, { root: true })
    return firebase.functions().httpsCallable('proposalDirectNegotiationAccept')({proposalId}).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  [types.DIRECT_NEGOTIATION_REFUSE] ({ commit, firebase }, { proposalId }) {
    commit('app/setLoading', true, { root: true })
    return firebase.functions().httpsCallable('proposalDirectNegotiationRefuse')({proposalId}).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  [types.FINISH] ({ commit, firebase }, { proposalId }) {
    commit('app/setLoading', true, { root: true })
    return firebase.functions().httpsCallable('proposalFinish')({proposalId}).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  [types.MOVE_TO_NEGOTIATION] ({ commit, firebase }, { proposalId }) {
    commit('app/setLoading', true, { root: true })
    return firebase.functions().httpsCallable('proposalMoveToNegotiation')({proposalId}).finally(() => {
      commit('app/setLoading', false, { root: true })
    })
  },
  async [types.CALCULATE] ({ firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()
    return request(token).post(`${apiHost()}/calculator/calculate`, data )
  },
  async [types.CREATE] ({ firebase }, {proposal, caseId, parcel}) {
    return firebase.functions().httpsCallable('proposalCreate')({ proposal, caseId, parcel })
  }
}
