const state = {
  id: null,
  fantasyName: null,
  calculateParams: null,
  onboarding: null,
  plan: null,
  channels: null,
  responsibleId: {},
  countTotal: {
    codes: 0,
    proposals: 0
  },
  payment: {},
  dasboardPanelResults: {},
  dasboardAccepted: {},
  dasboardCasesCount: {},
  dasboardInvites: {}
}

export default state
