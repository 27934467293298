import { RequireAutorization } from './routerAuth'
import { ROUTE_USERS } from './users'

export const ROUTE_CONFIG = {
  path: '/configuracoes',
  components: {
    configuration: () => import(/* webpackChunkName: "config" */'@/components/Configuration/Configuration')
  },
  children: [
    {
      name: 'Configuration',
      path: '',
      component: () => import(/* webpackChunkName: "config" */'@/components/Configuration/General'),
      meta: {title: 'Configurações Gerais'}
    },
    ROUTE_USERS,
    {
      name: 'ConfigurationTheme',
      path: 'tema',
      component: () => import(/* webpackChunkName: "config" */'@/components/Theme/Theme'),
      meta: {title: 'Configurações - Cores'}
    }
  ],
  beforeEnter: RequireAutorization
}
