export default {
  length: 0,
  list: [],
  listLoading: true,
  listRender: false,
  listByCase: [],
  listByCaseLoading: true,
  record: {},
  recordLoading: false
}
