export default class Paginator {
  constructor () {
    this.currentPage = null
    this.pages = {}
    this.query = null
  }

  setQuery (query) {
    this.query = query
  }

  setPage (index, startAt, endAt = null) {
		this.pages[index] = { startAt, endAt }
  }

  getPage (index) {
    return this.pages[index]
  }

  setCurrentPage (index) {
    this.currentPage = index
  }

  async has (index, currentPage) {
    const condition = this.pages.hasOwnProperty(index)

    if (!condition) {
      const records = await this.query.limit(1).get()
      if (records.docs.length) {
        const startAt = records.docs.first()
        this.setPage(currentPage, startAt)

        this.query = this.query.startAt(startAt)
      }
    }

    return this.query
  }

  lt (index) {
    const condition = this.currentPage && this.currentPage < index || false

    this.query = condition ? this.query.startAfter(this.getPage(this.currentPage).endAt) : this.query

    return this.query
  }

  gt (index) {
    const condition = this.currentPage && this.currentPage > index || false

    this.query = condition ? this.query.startAt(this.getPage(this.currentPage - 1).startAt) : this.query

    return this.query
  }

  reset () {
    this.currentPage = null
  	this.pages = {}
  }
}