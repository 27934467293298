import Vue from 'vue'
import VueRouter from 'vue-router'

// ROUTES
import { ROUTE_CASES } from './cases'
import { ROUTE_EVENTS } from './events'
import { ROUTE_PROPOSALS } from './proposals'
import { ROUTE_CONFIG } from './config'
import { ROUTE_SCHEDULE } from './schedule'
import { RequireAuthentication, RequireAutorization } from './routerAuth'
import { ROUTE_NOT_AUTHENTICATED } from './notAuthenticated'
import { ROUTE_MESSAGES } from './messages'
import { ROUTE_CENTRAL_CHANNELS } from './central'
import { ROUTE_FINANCIAL } from './financial'
import { ROUTE_REPORTS } from './reports'

Vue.use(VueRouter)

const routes = [
  // Authenticated routes
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Home" */'@/components/DashBoard'),
    meta: {title: 'Modera'},
    children: [
      {
        name: 'Theme',
        path: 'temas',
        component: () => import(/* webpackChunkName: "Theme" */'@/components/Theme/Theme'),
        meta: {title: 'Modera'},
        beforeEnter: RequireAutorization
      },
      {
        name: 'Home',
        path: '',
        component: () => import(/* webpackChunkName: "Home" */ '@/components/pages/Home'),
        meta: {title: 'Modera'},
        beforeEnter: RequireAutorization
      },
      {
        name: 'SurveyPage',
        path: '/resultados-das-pesquisas',
        component: () => import(/* webpackChunkName: "Survey" */'@/components/pages/Survey'),
        meta: {title: 'Resultados das pesquisas'},
        beforeEnter: RequireAutorization
      },
      {
        name: 'User',
        path: '/meu-perfil',
        component: () => import(/* webpackChunkName: "Profile" */'@/components/pages/User'),
        meta: {title: 'Meu Perfil'},
        beforeEnter: RequireAutorization
      },
      {
        name: 'VideoConference',
        path: '/videoconferencia',
        component: () => import(/* webpackChunkName: "VideoConference" */'@/components/VideoConference/VideoConference'),
        meta: {title: 'Videoconferência'},
        beforeEnter: RequireAutorization
      },
      ROUTE_EVENTS,
      ...ROUTE_CASES,
      ...ROUTE_PROPOSALS,
      ROUTE_SCHEDULE,
      ROUTE_MESSAGES,
      ROUTE_CENTRAL_CHANNELS,
      ROUTE_FINANCIAL,
      ROUTE_CONFIG,
      ROUTE_REPORTS
    ],
    beforeEnter: RequireAuthentication
  },
  ...ROUTE_NOT_AUTHENTICATED,
  {
    name: 'NotFound',
    path: '*',
    component: () => import(/* webpackChunkName: "Common" */'@/components/pages/NotFound')
  }
]

const router = new VueRouter({ routes, mode: 'history' })

export default router
