import * as types from './types'

const actions = {
  INIT ({}) {},
  async [types.GET_DASHBOARD_PANEL_RESULT] ({ commit, firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    return new Promise(resolve => {
      firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--panelResults--')
      .onSnapshot(async doc => {
        const data = doc.data()

        commit('setDasboardPanelResult', data || {})
        resolve(data)
      })
    })
  },
  async [types.GET_DASHBOARD_ACCEPTED] ({ commit, firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--installments--')
      .onSnapshot(async doc => {
        commit('setDasboardAccepted', doc.data() || {})
      })
  },
  async [types.GET_DASHBOARD_CASES_COUNT] ({ commit, firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    return new Promise(resolve => {
      firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--cases--')
      .onSnapshot(async doc => {
        const data = doc.data()

        commit('setDasboardCasesCount', data || {})
        resolve(data)
      })
    })
  },
  async [types.GET_DASHBOARD_INVITES] ({ commit, firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--invites--')
      .onSnapshot(async doc => {
        commit('setDasboardInvites', doc.data() || {})
      })
  },
  async [types.GET_INVITES_COUNTS_BY_CHANNEL] ({ firebase, rootGetters }, { channel, attempt }) {
    const currentCompany = rootGetters['currentCompany/id']

    let baseQuery = firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--invites--')
      .collection(channel)

    if (attempt !== null) {
      baseQuery = baseQuery.doc(String(attempt))
    }

    return baseQuery
  },
  async [types.GET_INVITES_COUNTS_ACCESS_BY_CHANNEL] ({ firebase, rootGetters }, { channel, attempt }) {
    const currentCompany = rootGetters['currentCompany/id']

    let baseQuery = firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--invites--')
      .collection(channel)

    if (attempt !== null) {
      baseQuery = baseQuery.doc(String(attempt))
    }

    return baseQuery
  },
  async [types.GET_INVITES_OPEN_VS_CLICK] ({ firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    return firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--invitesOpenVsClick--')
  }
}

export default actions
