// Auth
export const AUTH = 'auth'
// List
export const GET_LIST = 'getList'
export const SET_LIST = 'setList'
export const SET_LIST_LOADING = 'setListLoading'
export const SET_LIST_RENDER = 'setListRender'
// Record
export const GET = 'get'
export const SET = 'set'
export const UNSET = 'unset'
export const SET_RECORD_LOADING = 'setRecordLoading'
export const UPDATE = 'update'
export const UPDATE_ACCOUNT = 'updateAccount'

export const CREATE = 'create'
export const CHANGE_CURRENT_COMPANY = 'changeCurrentCompany'

export const CHANGE_PASSWORD = 'changePassword'
export const CHANGE_PICTURE = 'changePicture'
export const RESET_PASSWORD = 'resetPassword'
