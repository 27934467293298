import Vue from 'vue'
import { mapGetters } from 'vuex'

const CanMixin = (Vue) => {
  Vue.mixin({
    computed: {
      ...mapGetters({
        abilities: 'app/abilities',
        companyAbilities: 'app/companyAbilities'
      })
    },
    methods: {
      $can (ability) {
        return this.abilities.includes(ability)
      },
      $canForCompany (ability) {
        return this.companyAbilities && this.companyAbilities[ability]
      }
    }
  })
}

Vue.use(CanMixin)
