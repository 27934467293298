/* eslint-disable vue/return-in-computed-property */
<template>
  <v-text-field
    v-model="value"
    class="input-number"
    min="0"
    :rules="validate"
    :label="label"
    :append-icon="appendIcon"
    :disabled="disabled"
    required
    filled
    :data-cy="dataCy"
    v-on="vOn"
    @click:append="toggleMarker"
    @mousedown="setCursor"
    @keydown.native="keyDown"
    @input="input" />
</template>

<script>
  import numeral from 'numeral'

  // numeral.register('locale', 'pt-BR', {
  //   delimiters: {
  //     thousands: '.',
  //     decimal: ','
  //   },
  //   abbreviations: {
  //     thousand: 'k',
  //     million: 'm',
  //     billion: 'b',
  //     trillion: 't'
  //   },
  //   currency: {
  //     symbol: 'R$'
  //   }
  // })

  // numeral.locale('pt-BR')

  export default {
    props: {
      required: {
        type: Boolean,
        default: false
      },
      currency: {
        type: Boolean,
        default: false
      },
      max: {
        type: Number,
        default: 0.0
      },
      notReplace: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      setValue: {
        type: Number,
        default: 0
      },
      isRelativeMax: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      fixedType: {
        type: Boolean,
        default: false
      },
      dataCy: {
        type: String,
        default: ''
      },
      vOn: {
        type: Object
      }
    },
    data () {
      return {
        oldCursor: 0,
        oldValue: '0',
        cursorInteval: 0,
        isCurrency: this.currency,
        value: '0',
        maxValue: this.max,
        appendIcon: 'mdi-percent'
      }
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      validate () {
        if (this.appendIcon === '') {
          return [v => true]
        } else if (this.isCurrency && this.max !== 0.0) {
          return [v => parseFloat(v) <= this.maxValue.toFixed(2) || `Valor máximo é ${this.maxValue.toFixed(2)}`]
        } else if (this.appendIcon === 'mdi-percent') {
          if (this.isRelativeMax) {
            return [v => parseFloat(v) <= this.maxValue || `Porcentagem máxima é ${this.maxValue}.00`]
          }
          return [v => parseFloat(v) <= 100.00 || 'Porcentagem máxima é 100.00']
        }
      },
      format () {
        return this.isCurrency ? '$ 0,0.00' : '0.00%'
      }
    },
    beforeMount () {
      this.isCurrency = this.currency
      this.maxValue = this.max
      const setValue = String(this.setValue)
      this.value = setValue
      this.input(setValue)
      if (this.notReplace) this.appendIcon = ''
    },
    methods: {
      toggleMarker () {
        if (this.fixedType) {
          return
        }
        this.isCurrency = !this.isCurrency
        this.appendIcon = this.isCurrency ? 'mdi-currency-usd' : 'mdi-percent'
        this.input(this.value)
      },
      setSelectionRange (position) {
        this.$el.querySelector('input').setSelectionRange(position, position)
      },
      setCursor (e) {
        let $input = this.$el.querySelector('input')
        let strLength = $input.value.length
        this.setSelectionRange(strLength)
        this.oldCursor = $input.selectionEnd
        $input.focus()
        e.preventDefault()
        e.stopPropagation()
      },
      keyDown (e) {
        let $input = this.$el.querySelector('input')

        // if (e.keyCode === 46 || e.keyCode === 8) $input.value = $input.value.slice(0, -1)
        if ([37, 38, 39, 40].includes(e.keyCode)) this.setCursor(e)

        this.oldValue = $input.value
        this.oldCursor = $input.selectionEnd
      },
      input (newValue) {
        let unmaskedValue = this.unmask(newValue)
        let oldCursor = this.oldCursor
        let oldValue = this.oldValue
        let value = this.convertValue(unmaskedValue)
        newValue = this.mask(value)

        let newCursorPosition = this.newCursorPosition(oldCursor, oldValue, newValue)

        this.$emit('change', { value, isCurrency: this.isCurrency })

        this.$nextTick(() => {
          this.value = !this.notReplace ? newValue.replace('%', '').replace('R$ ', '') : newValue
          this.setSelectionRange(newCursorPosition)
        })
      },
      mask (value) {
        value = typeof value === 'string' ? value : value.toString()
        return numeral(value).format(this.format)
      },
      unmask (value) {
        value = typeof value === 'string' ? value : value.toString()
        return value.replace(new RegExp(/[^\d]/, 'g'), '')
      },
      convertValue (value) {
        let div = this.isCurrency ? 100 : 10000

        return parseFloat(value) / div
      },
      checkSeparator (position, interval) {
        return Math.floor(position / (interval + 1))
      },
      newCursorPosition (oldCursor, oldValue, newValue) {
        let cursorInteval = this.cursorInteval

        return oldCursor - this.checkSeparator(oldCursor, cursorInteval) + this.checkSeparator(oldCursor + (newValue.length - oldValue.length), cursorInteval) + (this.unmask(newValue).length - this.unmask(oldValue).length) // eslint-disable-line
      }
    }
  }
</script>

<style lang="sass">
  .input-number
    .icon
      font-size: 18px

    &.error--text
      .v-text-field__details
        white-space: nowrap
        overflow: visible
</style>
