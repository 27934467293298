export const ROUTE_NOT_AUTHENTICATED = [
  {
    name: 'Login',
    path: '/login',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@/components/pages/Login'),
    meta: {title: 'Modera - Login'}
  },
  {
    name: 'ForgotPassword',
    path: '/esqueci-minha-senha',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@/components/pages/ForgotPassword'),
    meta: {title: 'Modera - Esqueci minha senha'}
  },
  {
    name: 'Auth',
    path: '/auth*',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@/components/pages/Auth'),
    meta: {title: 'Modera - Validando token'}
  },
  {
    name: 'EmailInvite',
    path: '/email-convidado',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@/components/emails/InviteEmail'),
    meta: {title: 'Modera - Email'}
  },
  {
    name: 'Boleto',
    path: '/boleto/:hash',
    component: () => import(/* webpackChunkName: "notAuthenticated" */'@/components/BankSlip/Render'),
    meta: {title: 'Modera - Boleto'}
  },
  {
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */'@/components/chat/Chat'),
    path: '/chat'
  }
]
