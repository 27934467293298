import { processData, processRecords } from '@/utils'
import i18n from '@/plugins/i18n'
import Query from '@/utils/query'

export default {
  INIT ({}) {},
  async getList ({ commit, firebase, rootGetters }, paginate) {
    const page = paginate.page || 1
    const currentCompany = rootGetters['currentCompany/id']
    const eventsPath = `companies/${currentCompany}/events`
    let baseQuery = firebase.firestore().collection(eventsPath).orderBy('insertedAt', 'desc')
    const query = new Query(baseQuery, commit)
    baseQuery = await query.pageHas(1, page)
    baseQuery = query.pageNext(page)
    baseQuery = query.pagePrev(page)
    if(paginate.rowsPerPage > 0){
      baseQuery = baseQuery.limit(paginate.rowsPerPage)
    }
    baseQuery.onSnapshot(async snapshots => {
      let records = await processRecords(snapshots)
      records.length && query.paginator.setCurrentPage(page)
      records.length && query.paginator.setPage(page, snapshots.docs[0], snapshots.docs[snapshots.docs.length - 1])
      const countTotal = rootGetters['currentCompany/countTotal']
      commit('totalCount', countTotal && countTotal.events || 0)
      commit('setList', records)
      commit('setListLoading', false)
    })
  },
  async get ({ commit, firebase, rootGetters }, id) {
    const currentCompany = rootGetters['currentCompany/id']

    firebase
      .firestore()
      .collection(`companies/${currentCompany}/events`)
      .doc(id)
      .onSnapshot(async doc => {
        const record = await processData(doc)
        record.insertedAt = i18n.d(record.insertedAt.toDate(), 'long')

        commit('set', record)
        commit('setRecordLoading', false)
      })
  },
  async create ({ commit, firebase, rootGetters }, event) {
    const db = firebase.firestore()
    const companyId = rootGetters['currentCompany/id']
    const { FieldValue } = firebase.firestore
    const insertedAt = FieldValue.serverTimestamp()
    const id = event.id
    delete event.id
    const companyRef = db.collection('companies').doc(companyId)
    const eventRef = companyRef.collection('events').doc(id)
    const countsRef = companyRef.collection('stats').doc('--counts--')
    return db.runTransaction(async t => {
      await t.update(countsRef, { events: FieldValue.increment(1) })
      const countsSnaphsot = await countsRef.get()
      commit('currentCompany/setCurrentCompanyCounts', countsSnaphsot.data(), { root: true })
      return t.set(eventRef, { ...event, insertedAt })
    })
  }
}
