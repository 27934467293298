export default {
  'en-US': {
    'address': {
      'types': {
        'billing': 'Billing',
        'commercial': 'Commercial',
        'official': 'Official',
        'others': 'Others',
        'residential': 'Residential'
      }
    },
    'bankSlip': {
      'state': {
        'canceled': 'Canceled',
        'created': 'Created',
        'expired': 'Expired',
        'overdue': 'Overdue',
        'payed': 'Payed',
        'registered': 'Registered'
      }
    },
    'bankSlipReturnFile': {
      'state': {
        'created': 'Created'
      }
    },
    'batch': {
      'state': {
        'completed': 'Completed',
        'created': 'Created',
        'importing': 'Importing'
      }
    },
    'calculateParams': {
      'correctionIndex': 'Correcion Index',
      'correction_index': 'Correction Index',
      'fees': 'Fees',
      'fine': 'Fine',
      'interestRate': 'Interest Rate',
      'interest_rate': 'Interest Rate',
      'pass_expenses': 'Repay expenses',
      'rateInstallments': 'Rate Installments',
      'rate_installments': 'Interest on Installment'
    },
    'case': {
      'state': {
        'accepted': 'Approved',
        'canceled': 'Canceled',
        'created': 'Created',
        'directAgreement': 'Direct Agreement',
        'directDisagreement': 'Direct Disagreement',
        'directNegotiation': 'Direct Negotiation',
        'directRefused': 'Direct Refused',
        'draft': 'Analyze',
        'dropped': 'Dropped',
        'expired': 'Expired',
        'externalAgreement': 'External Agreement',
        'externalRefused': 'External Refused',
        'finished': 'Finished',
        'fruitless': 'Fruitless',
        'initialProposal': 'Initial Proposal',
        'initial_proposal': 'Initial Proposal',
        'invited': 'Invited',
        'mediation': 'In Mediation',
        'negotiation': 'In Negotiation',
        'refused': 'Disapproved',
        'renegotiated': 'Renegotiated',
        'suspended': 'Suspended',
        'visualized': 'Visualized'
      }
    },
    'channel': {
      'letter': 'Letter',
      'mail': 'Mail',
      'sms': 'SMS'
    },
    'dashboard': {
      'widgets': {
        'period': {
          'day': 'Day',
          'label': 'Select the time',
          'month': 'Month',
          'week': 'Week'
        }
      }
    },
    'event': {
      'procedure': {
        'extra_judicial': 'Extra Judicial',
        'judicial': 'Judicial'
      },
      'type': {
        'attendant': 'Attendant',
        'credit': 'Credit',
        'payment': 'Payment'
      }
    },
    'group': {
      'name': {
        'administrator': 'Administrator',
        'camara': 'Camara',
        'financial': 'Financial',
        'guest': 'Guest',
        'negotiator': 'Negotiator',
        'superuser': 'Superuser',
        'supervisor': 'Supervisor'
      }
    },
    'invite': {
      'channel': {
        'letter': 'Letter',
        'mail': 'Email',
        'sms': 'SMS'
      },
      'state': {
        'absentReturnedToSender': 'Absent Returned To Sender',
        'accessed': 'Accessed',
        'addressWithoutHomeDistribution': 'addressWithoutHomeDistribution',
        'bankruptCompany': 'Bankrupt Company',
        'bounce': 'Bounce',
        'canceled': 'Canceled',
        'click': 'Clicked',
        'created': 'Created',
        'deceased': 'Deceased',
        'declined': 'Declined',
        'delivered': 'Delivered',
        'documentationNotProvided': 'Documentation Not Provided',
        'dropped': 'Dropped',
        'forwarded': 'Forwarded',
        'insufficientAddress': 'Insufficient Address',
        'lostObject': 'Lost Object',
        'moved': 'Moved',
        'notWanted': 'Not Wanted',
        'notWantedReturnedToSender': 'Not Wanted Returned To Sender',
        'not_found': 'Address Not Found',
        'open': 'Opened',
        'processed': 'Processed',
        'scheduled': 'Scheduled',
        'sinister': 'Sinister',
        'spamreport': 'Spam Report',
        'stolen': 'Stolen',
        'thereIsNoMumberIndicated': 'There Is No Mumber Indicated',
        'unknown': 'Unknown',
        'used': 'Used'
      }
    },
    'panelResults': {
      'accepted': 'Accepted Cases',
      'draft': 'Analize Case',
      'dropped': 'Dropped Cases',
      'inProgress': 'In Progress Cases',
      'refused': 'Refused Cases'
    },
    'proposal': {
      'state': {
        'accepted': 'Accepted',
        'canceled': 'Canceled',
        'created': 'Created',
        'draft': 'Draft',
        'externalAgreement': 'External Agreement',
        'externalRefused': 'External Refused',
        'finished': 'Finished',
        'refused': 'Refused',
        'renegotiated': 'Renegotiated',
        'visualized': 'Visualized'
      },
      'ticket': {
        'state': {
          'payed': 'Payed',
          'registered': 'Registered'
        }
      }
    },
    'surveys': {
      'question_1': null,
      'question_10': null,
      'question_11': null,
      'question_2': null,
      'question_3': null,
      'question_4': null,
      'question_5': null,
      'question_6': null,
      'question_7': null,
      'question_8': null,
      'question_9': null,
      'stats': {
        'bad': 'Bad',
        'excellent': 'Excellent',
        'fair': 'Fair',
        'good': 'Good',
        'hopeNot': null,
        'maybe': null,
        'not': null,
        'sure': null,
        'veryGood': 'Very Good',
        'yes': null
      }
    },
    'task': {
      'state': {
        'completed': 'Completed',
        'in-progress': 'In Progress',
        'postponed': 'Postponed',
        'progress': 'Progress',
        'scheduled': 'Scheduled'
      }
    },
    'timeline': {
      'state': {
        'case_created': 'Created Case',
        'case_negotiation': 'In Negotiation',
        'directAgreement': 'Direct Agreement',
        'directRefused': 'Direct Refused',
        'externalAgreement': 'External Agreement',
        'externalRefused': 'External Refused',
        'finished': 'Finished',
        'invite_bounce': 'Invited Bounce',
        'invite_click': 'Access Invite',
        'invite_created': 'Created Invite',
        'invite_delivered': 'Delivered Invite',
        'invite_dropped': 'Dropped Invite',
        'invite_open': 'Invite opened',
        'invite_processed': 'Processed Invite',
        'proposal_accepted': 'Proposal Accepted',
        'proposal_canceled': 'Proposal Canceled',
        'proposal_created': 'Created Proposal',
        'proposal_refused': 'Proposal Refused',
        'proposal_visualized': 'Visualized Proposal',
        'term_delivered': 'Term Delivered',
        'term_open': 'Term Open',
        'term_processed': 'Term Processed'
      }
    },
    'user': {
      'phone': {
        'types': {
          'comercial': 'Commercial',
          'commercial': 'Commercial',
          'home': 'Residential',
          'mobile': 'Mobile',
          'others': 'Others',
          'residential': 'Residential'
        }
      },
      'phones': {
        'types': {
          'mobile': 'mobile'
        }
      }
    }
  },
  'pt-BR': {
    'address': {
      'types': {
        'billing': 'Cobrança',
        'commercial': 'Comercial',
        'official': 'Registro Oficial',
        'others': 'Outros',
        'residential': 'Residencial'
      }
    },
    'bankSlip': {
      'state': {
        'canceled': 'Cancelado',
        'created': 'Criado',
        'expired': 'Expirado',
        'overdue': 'Atrasado',
        'payed': 'Pago',
        'registered': 'Registrado'
      }
    },
    'bankSlipReturnFile': {
      'state': {
        'created': 'Criado'
      }
    },
    'batch': {
      'state': {
        'completed': 'Completado',
        'created': 'Criado',
        'importing': 'Importando'
      }
    },
    'calculateParams': {
      'correctionIndex': 'Correção Monetária',
      'correction_index': 'Correção Monetária',
      'fees': 'Honorários advocatícios',
      'fine': 'Multa moratória',
      'interestRate': 'Juros Moratórios',
      'interest_rate': 'Juros moratórios',
      'pass_expenses': 'Repassar despesas',
      'rateInstallments': 'Juros de Parcelamento',
      'rate_installments': 'Juros de Parcelamento'
    },
    'case': {
      'state': {
        'accepted': 'Proposta Aceita',
        'canceled': 'Encerrado',
        'created': 'Criado',
        'directAgreement': 'Acordo Presencial',
        'directDisagreement': 'Recusa Presencial',
        'directNegotiation': 'Negociação Presencial',
        'directRefused': 'Recusa Presencial',
        'draft': 'Análise',
        'dropped': 'Baixado',
        'expired': 'Expirado',
        'externalAgreement': 'Acordo Externo',
        'externalRefused': 'Recusa Externa',
        'finished': 'Finalizado',
        'fruitless': 'Infrutífero',
        'initialProposal': 'Proposta Inicial',
        'initial_proposal': 'Proposta Inicial',
        'invited': 'Convidado',
        'mediation': 'Em Mediação',
        'negotiation': 'Em Negociação',
        'refused': 'Proposta Recusada',
        'renegotiated': 'Renegociado',
        'suspended': 'Suspenso',
        'visualized': 'Visualizado'
      }
    },
    'channel': {
      'letter': 'Carta ',
      'mail': 'E-mail',
      'sms': 'SMS'
    },
    'dashboard': {
      'widgets': {
        'period': {
          'day': 'Dia',
          'label': 'Selecione o período',
          'month': 'Mês',
          'week': 'Semana'
        }
      }
    },
    'event': {
      'procedure': {
        'extra_judicial': 'Extra Judicial',
        'judicial': 'Judicial'
      },
      'type': {
        'attendant': 'Atendimento',
        'credit': 'Crédito',
        'payment': 'Pagamento'
      }
    },
    'group': {
      'name': {
        'administrator': 'Administrador',
        'camara': 'Camara',
        'financial': 'Financeiro',
        'guest': 'Guest',
        'negotiator': 'Negociador',
        'superuser': 'Superuser',
        'supervisor': 'Supervisor'
      }
    },
    'invite': {
      'channel': {
        'letter': 'Carta',
        'mail': 'Email',
        'sms': 'SMS'
      },
      'state': {
        'absentReturnedToSender': 'Ausente - Devolvido ao remetente',
        'accessed': 'Acessado',
        'addressWithoutHomeDistribution': 'Endereço sem distribuição domiciliar',
        'bankruptCompany': 'Empresa falida',
        'bounce': 'Falha na entrega',
        'canceled': 'Cancelado',
        'click': 'Clicado',
        'created': 'Criado',
        'deceased': 'Falecido',
        'declined': 'Desconhecido',
        'delivered': 'Entregue',
        'documentationNotProvided': 'Documentação não fornecida',
        'dropped': 'Recusado',
        'forwarded': 'Encaminhado',
        'insufficientAddress': 'Endereço insuficiente',
        'lostObject': 'Objeto extraviado',
        'moved': 'Mudou-se',
        'notWanted': 'Não Procurado',
        'notWantedReturnedToSender': 'Não procurado - Devolvido ao remetente',
        'not_found': 'Endereço não encontrado',
        'open': 'Aberto',
        'processed': 'Processado',
        'scheduled': 'Agendado',
        'sinister': 'Sinistro',
        'spamreport': 'Reportado como Spam',
        'stolen': 'Roubado',
        'thereIsNoMumberIndicated': 'Não existe o número indicado',
        'unknown': 'Destinatário Desconhecido',
        'used': 'Usado'
      }
    },
    'panelResults': {
      'accepted': 'Casos Aceitos',
      'draft': 'Casos em Análise',
      'dropped': 'Casos Baixados',
      'inProgress': 'Casos Em Andamento',
      'refused': 'Casos Recusados'
    },
    'proposal': {
      'state': {
        'accepted': 'Aceita',
        'canceled': 'Cancelada',
        'created': 'Criada',
        'draft': 'Em Análise',
        'externalAgreement': 'Acordo Externo',
        'externalRefused': 'Recusa Externa',
        'finished': 'Finalizada',
        'refused': 'Recusada',
        'renegotiated': 'Renegociada',
        'visualized': 'Visualizada'
      },
      'ticket': {
        'state': {
          'payed': 'pago',
          'registered': 'a pagar'
        }
      }
    },
    'surveys': {
      'question_1': 'Como você avalia o desempenho do {name}?',
      'question_10': 'Como você avalia o tempo gasto nessa operação?',
      'question_11': 'Como você avalia sua experiência conosco?',
      'question_2': 'Você utilizaria novamente nossa plataforma?',
      'question_3': 'Como você avalia o tempo gasto nessa operação?',
      'question_4': 'Como você avalia sua experiência com a nossa Plataforma?',
      'question_5': 'Como você avalia a nossa comunicação no convite enviado?',
      'question_6': 'O Mediador {name} auxiliou na comunicação com a outra parte?',
      'question_7': 'Como você avalia o desempenho do Mediador {name}?',
      'question_8': 'Como você avalia o desempenho do Representante da {company_name}?',
      'question_9': 'Como você avalia sua experiência com a nossa Plataforma?',
      'stats': {
        'bad': 'Ruim',
        'excellent': 'Excelente',
        'fair': 'Satisfatório',
        'good': 'Bom',
        'hopeNot': 'Espero que não',
        'maybe': 'Talvez',
        'not': 'Não',
        'sure': 'Com certeza',
        'veryGood': 'Muito Bom',
        'yes': 'Sim'
      }
    },
    'task': {
      'state': {
        'completed': 'Completado',
        'in-progress': 'Em Andamento',
        'postponed': 'Adiado',
        'progress': 'Em Progresso',
        'scheduled': 'Agendado'
      }
    },
    'timeline': {
      'state': {
        'case_created': 'Caso Criado',
        'case_negotiation': 'Em negociação',
        'directAgreement': 'Acordo Presencial',
        'directRefused': 'Recusa Presencial',
        'externalAgreement': 'Acordo Externo',
        'externalRefused': 'Recusa Externa',
        'finished': 'Finalizado',
        'invite_bounce': 'Falha na entrega do convite',
        'invite_click': 'Convite Acessado',
        'invite_created': 'Convite Criado',
        'invite_delivered': 'Convite Entregue',
        'invite_dropped': 'Convite Recusado',
        'invite_open': 'Convite aberto',
        'invite_processed': 'Convite Enviado',
        'proposal_accepted': 'Proposta Aceita',
        'proposal_canceled': 'Proposta Cancelada',
        'proposal_created': 'Proposta Criada',
        'proposal_refused': 'Proposta Recusada',
        'proposal_visualized': 'Proposta Visualizada',
        'term_delivered': 'Termo Entregue',
        'term_open': 'Termo Aberto',
        'term_processed': 'Termo Enviado'
      }
    },
    'user': {
      'phone': {
        'types': {
          'comercial': 'Comercial',
          'commercial': 'Comercial',
          'home': 'Residencial',
          'mobile': 'Celular',
          'others': 'Outros',
          'residential': 'Residencial\n'
        }
      },
      'phones': {
        'types': {
          'mobile': 'Celular'
        }
      }
    }
  }
}
