// List
export const GET_LIST = 'getList'
export const SET_LIST = 'setList'
export const SET_LIST_LOADING = 'setListLoading'
export const SET_LIST_RENDER = 'setListRender'
export const SET_START_AFTER = 'setStartAfter'
export const SET_START_AT = 'setStartAt'
export const SET_PAGE = 'setPage'
// Import
export const IMPORT = 'import'
