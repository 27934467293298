import * as types from './types'
import { processRecords, request } from '@/utils'
import { USER_ID, AUTH_TOKEN, GROUPS } from '@/utils/index'

const actions = {
  INIT() { },
  async [types.UPDATE]({ firebase }, data) {
    const user = firebase.auth().currentUser
    const userData = {
      name: data.name
    }
    if (data.birthDate) {
      userData.birthDate = data.birthDate
    }
    await firebase.firestore().collection('users').doc(user.uid).update(userData)
    return user.updateProfile({ displayName: data.name })
  },
  async [types.UPDATE_ACCOUNT]({ firebase }, data) {
    return firebase.functions().httpsCallable('userUpdate')(data)
  },
  getAuth({ commit, state }) {
    let token = localStorage.getItem(AUTH_TOKEN)
    let userId = localStorage.getItem(USER_ID)

    if (state.user.token === null && token !== null) {
      commit('setToken', token)
    }
    if (state.user.userId === null && userId !== null) {
      commit('setUserId', userId)
    }
  },
  getGroups({ commit, state }) {
    let groups = localStorage.getItem(GROUPS)
    if (state.groups.length === 0 && groups !== null) {
      commit('setGroups', JSON.parse(groups))
    }
  },
  async [types.GET_LIST]({ commit, firebase, rootGetters }, group) {
    const companyId = rootGetters['currentCompany/id']
    const obj = {}
    obj[companyId] = group

    commit('setListLoading', true)

    firebase
      .firestore()
      .collection('users')
      .where('companies', 'array-contains', obj)
      .orderBy('name', 'asc')
      .onSnapshot(async documentSnapshots => {
        let records = await processRecords(documentSnapshots)

        commit('setList', records)
        commit('setListLoading', false)
      })

  },
  async [types.AUTH]({ firebase }, data) {
    return request().post('/functions/auth', { data }).then(res => {
      return new Promise((resolve, reject) => {
        if (res.status === 200) {
          firebase
            .auth()
            .signInWithCustomToken(res.data.token)
            .then(authRes => {
              resolve({
                ...authRes,
                caseId: res.data.caseId
              })
            })
            .catch(reject)
        } else {
          reject()
        }
      })
    })
  },
  async [types.GET]({ firebase }, id) {
    return firebase.firestore().collection('users').doc(id).get()
  },
  async [types.CHANGE_CURRENT_COMPANY]({ firebase }, data) {
    return firebase.functions().httpsCallable('userChangeCurrentCompany')(data).then(res => {
      return firebase.auth().signInWithCustomToken(res.data.token)
    })
  },
  async [types.CREATE]({ firebase }, data) {
    return firebase.functions().httpsCallable('userCreate')(data)
  },
  async [types.CHANGE_PASSWORD]({ commit, firebase }, { currentPassword, newPassword }) {
    commit('app/setLoading', true, { root: true })
    const currentUser = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword)
    try {
      await currentUser.reauthenticateWithCredential(credential)
      return currentUser.updatePassword(newPassword)
    } catch (error) {
      throw error
    } finally {
      commit('app/setLoading', false, { root: true })
    }
  },
  async [types.RESET_PASSWORD]({ commit, firebase }, email) {
    commit('app/setLoading', true, { root: true })
    const auth = firebase.auth()
    try {
      return auth.sendPasswordResetEmail(email)
    } catch (error) {
      throw error
    } finally {
      commit('app/setLoading', false, { root: true })
    }
  },
  async [types.CHANGE_PICTURE]({ commit, firebase }, file) {
    commit('app/setLoading', true, { root: true })
    const user = firebase.auth().currentUser
    const storageRef = firebase.storage().ref()
    const fileParts = file.name.split('.')
    const extension = fileParts[fileParts.length - 1]
    const path = `users/${user.uid}/public/profile.${extension}`
    const snapshot = await storageRef.child(path).put(file, { contentType: file.type })
    const imageUrl = await snapshot.ref.getDownloadURL()
    const data = { photoURL: imageUrl }
    await firebase.firestore().collection('users').doc(user.uid).update(data)
    return user.updateProfile(data).finally(_ => {
      commit('app/setLoading', false, { root: true })
    })
  }
}

export default actions
