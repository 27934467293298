import { RequireAutorization } from './routerAuth'

export const ROUTE_EVENTS = {
  name: 'Events',
  path: '/campanhas',
  component: () => import(/* webpackChunkName: "Events" */'@/components/Events/Events'),
  meta: {title: 'Campanhas'},
  children: [
    {
      path: 'criar',
      name: 'EventNew',
      component: () => import(/* webpackChunkName: "EventNew" */'@/components/Events/New'),
      meta: { title: 'Criar campanha' }
    },
    {
      path: ':eventId',
      component: () => import(/* webpackChunkName: "EventShow" */'@/components/Events/Show'),
      meta: { title: 'Detalhes da campanha' },
      children: [
        {
          path: '',
          name: 'EventInfo',
          component: () => import(/* webpackChunkName: "EventShow" */'@/components/Events/context/Info'),
          meta: { title: 'Detalhes da campanha', subcontext: false }
        },
        {
          path: 'proposta-inicial',
          name: 'EventInitialProposal',
          component: () => import(/* webpackChunkName: "EventShow" */'@/components/Events/context/InitialProposal'),
          meta: { title: 'Proposal inicial', subcontext: true }
        },
        {
          path: 'negociacao-online',
          name: 'EventNegotiationOnline',
          component: () => import(/* webpackChunkName: "EventShow" */'@/components/Events/context/NegotiationOnline'),
          meta: { title: 'Negociação Online', subcontext: true }
        },
        // {
        //   path: 'negociacao-presencial',
        //   name: 'EventNegotiationFaceToFace',
        //   component: () => import(/* webpackChunkName: "EventShow" */'@/components/Events/context/NegotiationFaceToFace'),
        //   meta: { title: 'Negociação Presencial', subcontext: true }
        // },
        {
          path: 'mediacao-online',
          name: 'EventMediationOnline',
          component: () => import(/* webpackChunkName: "EventShow" */'@/components/Events/context/MediationOnline'),
          meta: { title: 'Mediação Online', subcontext: true }
        },
        {
          path: '*',
          name: 'EventError',
          component: () => import(/* webpackChunkName: "EventShow" */'@/components/ui/ContextNotFound'),
          meta: {title: 'Essa página não existe'}
        }
      ]
    }
  ],
  beforeEnter: RequireAutorization
}
