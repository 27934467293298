import * as types from './types'
import Vue from 'vue'
import { set } from '@/utils/vuex'

export default {
  [types.SET_LENGTH] (state, payload) {
    state.length = payload
  },
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
  [types.SET_LIST_BY_CASE] (state, payload) {
    state.listByCase = payload
  },
  [types.SET_LIST_BY_CASE_LOADING] (state, payload) {
    state.listByCaseLoading = payload
  },
  [types.SET_LIST_RENDER] (state, payload) {
    state.listRender = payload
  },
  record: set('record'),
  recordLoading: set('recordLoading'),
}
