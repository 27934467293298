export const GET_LIST = 'getList'
export const SET_LIST = 'setList'
export const SET_LENGTH = 'setLength'
export const SET_LIST_LOADING = 'setListLoading'
export const SET_LIST_RENDER = 'setListRender'
export const GET_LIST_BY_CASE = 'getListByCase'
export const SET_LIST_BY_CASE = 'setListByCase'
export const SET_LIST_BY_CASE_LOADING = 'setListByCaseLoading'
export const RESEND = 'resend'
export const ADD = 'add'
