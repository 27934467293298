const PAYMENTS = {
  ticket: {
    title: 'Boleto',
    kind: 'ticket',
    icon: 'ticket',
    iconPath: 'layout',
    color: 'channelEmail'
  },
  creditCard: {
    title: 'Cartão',
    kind: 'creditCard',
    icon: 'credit_card',
    iconPath: 'layout',
    color: 'channelSms'
  },
  generateFile: {
    title: 'Arquivo',
    kind: 'generateFile',
    icon: 'generator_file',
    iconPath: 'layout',
    color: 'channelLetter'
  },
  reports: {
    title: 'Relatórios',
    kind: 'reports',
    icon: 'reports',
    iconPath: 'link',
    color: 'secondary'
  },
  pagarme: {
    title: 'Pagar.me',
    kind: 'pagarme',
    icon: 'pagarme',
    iconPath: 'link',
    color: 'secondary'
  },
}

const getters = {
  id (state) {
    return state.id
  },
  name (state) {
    return state.fantasyName
  },
  calculateParams (state) {
    return state.calculateParams
  },
  plan (state) {
    return state.plan
  },
  responsibleId (state) {
    return state.responsibleId
  },
  onboarding (state) {
    return state.onboarding
  },
  channels (state) {
    return state.channels
  },
  countTotal (state) {
    return state.countTotal
  },
  payment (state) {
    const methods = state && state.payment ? Object.keys(state.payment) : []
    return  methods.filter(key => state.payment[key])
                   .map(item => PAYMENTS[item])
  },
  dasboardPanelResults (state) {
    return state.dasboardPanelResults
  },
  dasboardAccepted (state) {
    return state.dasboardAccepted
  },
  dasboardCasesCount (state) {
    return state.dasboardCasesCount || {}
  },
  dasboardInvites (state) {
    return state.dasboardInvites
  }
}

export default getters
