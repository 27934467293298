import { link } from '@/utils/icons'

export default {
  menuLink: [
    { title: 'Campanhas', icon: link.events, name: 'Events' },
    { title: 'Casos', icon: link.cases, name: 'Cases', params: { type: 'todos' } },
    { title: 'Agenda', icon: link.schedule, name: 'Schedule' },
    { title: 'Comunicação', icon: link.channels, name: 'Central' },
    { title: 'Mensagens', icon: link.messages, name: 'Messages' },
    { title: 'Financeiro', icon: link.financial_indices, name: 'BankSlip' },
    { title: 'Relatórios', icon: link.reports, name: 'ReportsTicketsPaid' }
  ]
}
