import { RequireAutorization } from './routerAuth'

export const ROUTE_MESSAGES = {
  name: 'Messages',
  path: '/mensagens',
  component: () => import(/* webpackChunkName: "Messages" */'@/components/Messages/Messages'),
  meta: {title: 'Mensagens'},
  children: [
    {
      name: 'MessagesNew',
      path: 'nova',
      component: () => import(/* webpackChunkName: "MessageNew" */'@/components/Messages/New'),
      meta: {title: 'Nova mensagem'}
    },
    {
      path: ':messageId',
      name: 'MessageShow',
      component: () => import(/* webpackChunkName: "MessageShow" */'@/components/Messages/Show'),
      meta: { title: 'Mensagem' }
    }
  ],
  beforeEnter: RequireAutorization
}
