import Vue from 'vue'
import VueTimeago from 'vue-timeago'
import pt from 'date-fns/locale/pt'

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'pt',
  locales: {
    pt: pt
  }
})
