<template>
  <v-layout>
    <v-dialog
      ref="dialog"
      v-model="show"
      :content-class="setClasses + ' modal ' + contentClass"
      :fullscreen="fullscreen"
      :transition="animation"
      :max-width="maxWidth"
      :persistent="persistent"
      :hide-overlay="!overlay"
      :scrollable="scrollable"
      :no-click-animation="noClickAnimation"
      @keydown.esc="close"
      @input="close">
      <v-card class="mb-0">
        <v-toolbar
          v-if="toolbar"
          height="45"
          dark
          color="primary">
          <h2 class="title">
            <slot name="title" />
          </h2>
          <v-spacer />
          <v-btn
            v-if="!hideClose"
            aria-label="Pressione para fechar Modal"
            icon
            dark
            @click.native="close">
            <v-icon>{{ $root.icons.action.close }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title
          v-else
          class="modal-height pl-6 pb-2 pt-3 pr-6">
          <h2 class="headline darkGray--text">
            <slot name="title" />
          </h2>
          <v-spacer />

          <v-btn
            v-if="!hideClose"
            aria-label="Pressione para fechar Modal"
            class="modal__close"
            icon
            small
            @click.native="close">
            <v-icon>{{ $root.icons.action.close }}</v-icon>
          </v-btn>
        </v-card-title>

        <slot name="header" />

        <v-card-text class="pl-6 pb-6 pt-1 mt-3 pr-6 modal-content">
          <slot name="content" />
        </v-card-text>

        <slot name="footer" />

        <div
          v-if="!hideActions"
          class="text-right px-6 mb-2">
          <v-btn
            data-cy="cancel"
            :color="btnSecondaryColor"
            text
            @click.native="emitClose">
            {{ btnSecondaryLabel }}
          </v-btn>
          <v-btn
            data-cy="submit"
            :loading="isLoading"
            :color="btnPrimaryColor"
            :disabled="!valid"
            text
            @click.native="submit">
            {{ btnPrimaryLabel }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Modal',
    props: {
      show: {
        type: Boolean,
        require: true,
        default: false
      },
      maxWidth: {
        type: String,
        default: '720px'
      },
      dataCy: {
        type: String,
        default: ''
      },
      persistent: {
        type: Boolean,
        default: true
      },
      noClickAnimation: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: true
      },
      toolbar: {
        type: Boolean,
        default: false
      },
      overlay: {
        type: Boolean,
        default: true
      },
      scrollable: {
        type: Boolean,
        default: true
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      hideActions: {
        type: Boolean,
        default: false
      },
      hideClose: {
        type: Boolean,
        default: false
      },
      btnPrimaryColor: {
        type: String,
        default: 'primary'
      },
      btnSecondaryColor: {
        type: String,
        default: 'darkGray'
      },
      btnPrimaryLabel: {
        type: String,
        default: 'Salvar'
      },
      btnSecondaryLabel: {
        type: String,
        default: 'Cancelar'
      },
      central: {
        type: Boolean,
        default: false
      },
      contentClass: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      // notifications: false,
      // sound: true,
      // widgets: false
    }),
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      }),
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      animation () {
        if (this.isMobile || this.fullscreen) {
          return 'dialog-bottom-transition'
        } else {
          return 'dialog-transition'
        }
      },
      isToolbar () {
        return true

        // I have doubt
        // if (this.isMobile && this.toolbar === false) {
        //    return true
        //  } else {
        //    return this.fullscreen
        //  }
      },
      setClasses () {
        if (this.central) {
          return `modal-central ${this.dataCy}`
        } else {
          return this.dataCy
        }
      }
    },
    methods: {
      close () {
        !this.hideClose && this.emitClose()
      },
      submit () {
        this.$emit('submit')
      },
      emitClose () {
        this.$emit('close')
      }
    }
  }
</script>


<style lang="sass">
  .box-parcel
    .modal-content
      margin-top: 0!important

  .modal
    position: relative
    border-radius: $radiusDefault

    .modal-height
      max-height: 55px

    .modal-content
      color: $textColor

      .pdfobject-container
        height: calc(100vh - 170px)

    .table-chat
      box-shadow: none !important

    &__close
      position: absolute
      top: 10px
      right: 10px

  .modal-central
    left: 0
    box-shadow: none
    width: calc(100% - #{$menuMainLarger})
    top: $topbarHeight
    border-radius: 0
    position: absolute

  .has-contextual-area,
  .has-chat-messages
    .modal-central
      width: calc(100% - (#{$sidebarLarger} + #{$menuMainLarger}))

  @media screen and (min-width: $min-full)

    .modal-central
      left: $menuMainLarger
      // width: calc(100% - (#{$menuMainLarger} * 2))
      width: calc(100% - #{$menuMainLarger} )

    .has-contextual-area
      .modal-central
        width: calc(100% - (#{$sidebarLarger} + #{$menuMainLarger}))

  @media screen and (max-width: $max-tablet)
    .has-contextual-area
      .modal-central
        width: 100vw

</style>
