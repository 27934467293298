import { RequireAutorization } from './routerAuth'

export const ROUTE_USERS = {
  name: 'Users',
  path: 'usuarios/:group',
  component: () => import(/* webpackChunkName: "Users" */'@/components/pages/Users'),
  meta: {title: 'Usuários'},
  children: [
    {
      path: 'criar',
      name: 'UserNew',
      component: () => import(/* webpackChunkName: "UserNew" */'@/components/users/New'),
      meta: { title: 'Criar usuário' }
    },
    {
      path: 'criar-mediador',
      name: 'UserNewMediator',
      component: () => import(/* webpackChunkName: "UserNew" */'@/components/users/NewMediator'),
      meta: { title: 'Criar Mediador' }
    },
    {
      path: ':userId',
      name: 'UserShow',
      component: () => import(/* webpackChunkName: "UserShow" */'@/components/users/Show'),
      meta: { title: 'Detalhes do usuário' },
      children: [
        {
          path: 'geral',
          name: 'UserGeneral',
          component: () => import(/* webpackChunkName: "UserShow" */'@/components/users/context/General'),
          meta: {title: 'Informações Gerais'}
        }
      ]
    }
  ],
  beforeEnter: RequireAutorization
}
