/* eslint-disable no-prototype-builtins */
/* eslint-disable no-extend-native */
!Object.prototype.renameProperty && Object.defineProperty(Object.prototype, 'renameProperty', {
  writable : false, // Cannot alter this property
  enumerable : false, // Will not show up in a for-in loop.
  configurable : false, // Cannot be deleted via the delete operator
  value : function (oldName, newName) {
      // Do nothing if the names are the same
      if (oldName === newName) {
          return this
      }

      if (this.hasOwnProperty(oldName)) {
          this[newName] = this[oldName]
          delete this[oldName]
      }
      return this
  }
})

!Object.prototype.takeProperties && Object.defineProperty(Object.prototype, 'takeProperties', {
  writable : false, // Cannot alter this property
  enumerable : false, // Will not show up in a for-in loop.
  configurable : false, // Cannot be deleted via the delete operator
  value : function (attributes) {
    for (var prop in this) {
      if ( !attributes.includes(prop) ) {
        delete this[prop]
      }
    }

    return this
  }
})


!Object.prototype.clone && Object.defineProperty(Object.prototype, 'clone', {
  writable : true, // Cannot alter this property
  enumerable : false, // Will not show up in a for-in loop.
  configurable : false, // Cannot be deleted via the delete operator
  value : function (attributes) {
    return JSON.parse(JSON.stringify(this))
  }
})

Array.prototype.first = function () {
  return this[0]
}

Array.prototype.last = function () {
  return this[this.length - 1]
}

Array.prototype.sum = function (initial) {
  return this.reduce((acc, value) => acc + value, initial)
}

Array.prototype.mapBy = function (field) {
  return this.map(item => item[field])
}

Array.prototype.flatten = function () {
  return Array.from(this.reduce((combined, item) => {
    return new Set([...combined, ...item])
  }, new Set()))
}

Array.prototype.uniq = function () {
  return [...new Set(this)]
}

Array.prototype.clear = function () {
  return this.filter(item => item)
}

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))]
}

Array.prototype.groupBy = function (keyGetter) {
  const map = new Map()
  this.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
