import { RequireAutorization } from './routerAuth'

export const ROUTE_REPORTS = {
  path: '/relatorios',
  component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Reports.vue'),
  beforeEnter: RequireAutorization,
  children: [
    {
      name: 'ReportsTicketsPaid',
      path: '',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Tickets/Paid/List'),
      meta: {title: 'Relatórios - Boletos Pagos'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ReportsTicketsRegistered',
      path: 'boletos-a-receber',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Tickets/Registered/List'),
      meta: {title: 'Relatórios - Boletos a Receber'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ReportsTicketsExpired',
      path: 'boletos-expirados',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Tickets/Expired/List'),
      meta: {title: 'Relatórios - Boletos Expirados'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ReportsCommunicationList',
      path: 'canais-de-comunicacao',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Communication/List'),
      meta: {title: 'Relatórios - Canais de Comunicação'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ReportsCasesDroppedList',
      path: 'casos-baixados',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/CasesDropped/List'),
      meta: {title: 'Relatórios - Casos Baixados'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ReportsCasesRefusedList',
      path: 'casos-recusados',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/CasesRefused/List'),
      meta: {title: 'Relatórios - Casos Recusados'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ProposalsAccepted',
      path: 'acordos-aceitos',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Proposals/Accepted/List'),
      meta: {title: 'Relatórios - Acordos Aceitos'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ProposalsNotAccepted',
      path: 'acordos-nao-aceitos',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Proposals/NotAccepted/List'),
      meta: {title: 'Relatórios - Acordos Não Aceitos'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'ProposalsPayments',
      path: 'pagamentos',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Proposals/Payments/List'),
      meta: {title: 'Relatórios - Pagamentos'},
      beforeEnter: RequireAutorization
    },
    {
      name: 'SurveyResults',
      path: 'resultados-pesquisas',
      component: () => import(/* webpackChunkName: "reportsRoute" */'@/components/Reports/Surveys/List'),
      meta: {title: 'Relatórios - Pesquisa de Satisfação'},
      beforeEnter: RequireAutorization
    }
  ]
}
