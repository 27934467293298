import * as types from './types'
import { request, apiHost } from '@/utils'

export default {
  INIT({ }) { },
  async [types.SEARCH]({ commit, firebase }, document) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('setLoading', true)
    return request(token)
      .post(`${apiHost()}/investigator/search`, { document })
      .then(response => response.data)
      .finally(_ => commit('setLoading', false))
  },
  async [types.SEARCH_BY_PROPERTY_REGISTRATION]({ commit, firebase }, subscription) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('setLoading', true)
    return request(token)
      .post(`${apiHost()}/giap/debit`, { subscription })
      .then(response => response.data)
      .finally(_ => commit('setLoading', false))
  },
  async [types.CONTACTS]({ commit, firebase }, caseId) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('setLoading', true)
    return request(token)
      .post(`${apiHost()}/investigator/contacts`, { caseId })
      .then(response => response.data)
      .finally(_ => commit('setLoading', false))
  }
}
