import * as types from './types'

export default {
  [types.SET_LENGTH] (state, payload) {
    state.length = payload
  },
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
  [types.SET_LIST_RENDER] (state, payload) {
    state.listRender = payload
  }
}
