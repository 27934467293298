import { get } from '@/utils/vuex'

export default {
  companyName: get('companyName'),
  camaraName: get('camaraName'),
  guestName: get('guestName'),
  length (state) {
    return state.length
  },
  loading (state) {
    return state.loading
  },
  list (state) {
    return state.list
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  listRender (state) {
    return state.listRender
  },
  paginate (state) {
    return state.paginate
  },
  record (state) {
    return state.record
  },
  recordLoading (state) {
    return state.recordLoading
  },
  participants (state) {
    return state.participants
  },
  participantsLoading (state) {
    return state.participantsLoading
  },
  page (state) {
    return state.page
  },
  forward (state) {
    return state.forward
  },
  backward (state) {
    return state.backward
  },
  timeline (state) {
    return state.timeline
  },
  timelineLoading (state) {
    return state.timelineLoading ? 1 : 0
  },
  messages (state) {
    return state.messages
  },
  messagesLoading (state) {
    return state.messagesLoading ? 1 : 0
  },
  documents (state) {
    return state.documents
  },
  documentsLoading (state) {
    return state.documentsLoading
  },
  notes (state) {
    return state.notes
  },
  notesLoading (state) {
    return state.notesLoading ? 1 : 0
  },
  tasks (state) {
    return state.tasks
  },
  tasksLoading (state) {
    return state.tasksLoading ? 1 : 0
  },
  report (state) {
    return state.report
  }
}
