import { request, apiHost } from '@/utils'

export default {
  INIT() { },
  async goToNegotiate({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/case/goToNegotiate`, data)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(err)
        })
    })
  },
  async close({ commit, firebase }, caseId) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/case/${caseId}/close`)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(err)
        })
    })
  },
  async refuseExternal({ commit, firebase }, data) {
    const caseId = data.caseId
    delete data.caseId

    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/case/${caseId}/refuseExternal`)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(err)
        })
    })
  },
  async drop({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()
    const caseId = data.caseId
    delete data.caseId
    return request(token).post(`${apiHost()}/case/${caseId}/drop`, data)
  }
}
