import { store } from '@/store'

export const RequireAuthentication = async (_to, _from, next) => {
  const isAuthenticated = await store.dispatch('app/auth')
  store.dispatch('user/getAuth')
  let token = store.getters['user/getToken']

  if (!token || token === null || token === 'null' || token === '') {
    next({ name: 'Login' })
  } else {
    next()
  }
  next(false)
}

export const RequireAutorization = (to, _from, next) => {
  store.dispatch('app/load').then(() => {
    store.dispatch('app/presence', to)

    const abilities = store.getters['app/abilities']
    const routeName = to.name
    if (abilities.includes(routeName)) {
      next()
    } else {
      console.error(`Você não tem permissao para acessar ${routeName}`)
      // next({ name: 'Home' })
      next()
    }
  })

}
