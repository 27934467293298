import { dateDiffInDays } from '@/utils'

export default {
  length (state) {
    return state.length
  },
  list (state) {
    return state.list
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  listRender (state) {
    return state.listRender
  },
  slider (state) {
    return state.slider
  },
  record (state) {
    return state.record
  },
  simulated_renegotiation (state) {
    return state.simulated_renegotiation
  },
  firstName (state) {
    return state.record && state.record.case && state.record.case.recipient && state.record.case.recipient.name.split(' ')[0]
  },
  name (state) {
    return state.record && state.record.case && state.record.case.recipient && state.record.case.recipient.name
  },
  days (state) {
    return state.record && state.record.expireAt && dateDiffInDays(new Date(), state.record.expireAt.toDate())
  },
  percent (state) {
    if (state.record && state.record.expireAt) {
      const expiration_days = 90
      return dateDiffInDays(new Date(), state.record.expireAt.toDate()) / expiration_days * 100
    }

    return 0
  },
  rangeValue (state) {
    return state.rangeValue
  },
  firstActive (state) {
    return state.firstActive
  },
  lastForCase (state) {
    return state.lastForCase
  },
  firstActiveForCase (state) {
    return state.firstActiveForCase
  },
  firstAccepted (state) {
    return state.firstAccepted
  },
  firstActiveLoading (state) {
    return state.firstActiveLoading
  },
  listByCase (state) {
    return state.listByCase
  },
  listByCaseLoading (state) {
    return state.listByCaseLoading ? 1 : 0
  }
}
