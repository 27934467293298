export default {
  companyName: '',
  camaraName: '',
  guestName: '',
  length: 0,
  list: [],
  listLoading: true,
  listRender: false,
  loading: false,
  paginate: {
    state: '',
    search: '',
    sortBy: 'code',
    descending: true,
    page: 1,
    rowsPerPage: 13,
    rowsPerPageItems: [13, 25, 50, 100],
    totalItems: 1642
  },
  page: 1,
  record: {
    event: {
      calculateParams: {}
    },
    recipient: {
      address: {}
    },
    insertedAt: {
      toDate() {}
    },
    updatedAt: {
      toDate() {}
    }
  },
  recordLoading: true,
  participants: [],
  participantsLoading: true,
  timeline: [],
  timelineLoading: true,
  messages: [],
  messagesLoading: true,
  documents: [],
  documentsLoading: true,
  notes: [],
  notesLoading: true,
  tasks: [],
  tasksLoading: true,
  report: {}
}
