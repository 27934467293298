import { RequireAutorization } from './routerAuth'

const ContextualArea = () => import(/* webpackChunkName: "CaseShowContextualArea" */'@/components/ui/ContextualArea')

export const ROUTE_CASES = [
  {
    path: '/casos',
    component: () => import(/* webpackChunkName: "CasesRoute" */'@/components/Cases/Cases'),
    beforeEnter: RequireAutorization,
    children: [
      {
        name: 'MultiplesCases',
        path: 'lote',
        component: () => import(/* webpackChunkName: "MultipleCases" */'@/components/MultiplesCases/List'),
        meta: {title: 'Importar Casos'},
        beforeEnter: RequireAutorization,
        children: [
          {
            name: 'MultiplesCasesNew',
            path: 'criar',
            component: () => import(/* webpackChunkName: "MultipleCases" */'@/components/MultiplesCases/New'),
            meta: {title: 'Nova Importação'},
            beforeEnter: RequireAutorization
          }
        ]
      },
      {
        path: ':type',
        name: 'Cases',
        component: () => import(/* webpackChunkName: "CasesRoute" */'@/components/Cases/Layout'),
        meta: { title: 'Casos'},
        props: { default: true, view: false },
        children: [
          {
            path: 'criar',
            name: 'CaseNew',
            component: () => import(/* webpackChunkName: "CaseNew" */'@/components/Cases/New'),
            meta: { title: 'Criar caso' }
          },
          {
            path: ':caseId',
            component: () => import(/* webpackChunkName: "CaseShow" */'@/components/Cases/Show'),
            meta: { title: 'Caso ativo' },
            beforeEnter: RequireAutorization,
            children: [
              {
                path: '/',
                name: 'CaseShow',
                beforeEnter: RequireAutorization
              },
              {
                path: 'geral',
                name: 'CaseGeneral',
                component: () => import(/* webpackChunkName: "CaseShowGeneral" */'@/components/Cases/Context/General'),
                meta: {title: 'Informações Gerais'},
                props: { default: true, view: false },
                beforeEnter: RequireAutorization
              },
              {
                path: 'timeline',
                name: 'CaseTimeline',
                component: () => import(/* webpackChunkName: "CaseShowTimeline" */'@/components/Cases/Context/Timeline'),
                meta: {title: 'Timeline'},
                props: { default: true, view: false },
                beforeEnter: RequireAutorization
              },
              {
                path: 'titulos',
                component: () => import(/* webpackChunkName: "CaseShowTickets" */'@/components/Cases/Context/Tickets'),
                children: [
                  {
                    path: '/',
                    name: 'CaseTicketsList',
                    component: () => import(/* webpackChunkName: "CaseShowTickets" */'@/components/Cases/Context/TicketsList'),
                    meta: {title: 'Títulos'},
                    beforeEnter: RequireAutorization
                  },
                  {
                    path: 'novo-titulo',
                    component: () => import(/* webpackChunkName: "CaseShowTickets" */'@/components/Cases/Context/TicketsNew'),
                    name: 'CaseTicketsNew',
                    meta: { title: 'Adicionar novo título' },
                    beforeEnter: RequireAutorization
                  }
                ]
              },
              {
                path: 'chat',
                name: 'CaseChat',
                component: () => import(/* webpackChunkName: "CaseShowChat" */'@/components/Cases/Context/Chat'),
                meta: {title: 'Chat'},
                props: { default: true, view: false },
                beforeEnter: RequireAutorization
              },
              {
                path: 'documentos',
                name: 'CaseDocuments',
                component: () => import(/* webpackChunkName: "CaseShowDocuments" */'@/components/Cases/Context/Documents'),
                meta: {title: 'Documentos utilizados'},
                props: { default: true, view: false },
                beforeEnter: RequireAutorization
              },
              {
                path: 'canais-de-comunicacao',
                component: () => import(/* webpackChunkName: "CaseShowChannels" */'@/components/Cases/Context/Channels'),
                children: [
                  {
                    path: '/',
                    name: 'CaseChannelsList',
                    component: () => import(/* webpackChunkName: "CaseShowChannelsList" */'@/components/Cases/Context/ChannelsList'),
                    meta: {title: 'Canais de comunicação'},
                    beforeEnter: RequireAutorization
                  },
                  {
                    path: 'novo-envio',
                    component: () => import(/* webpackChunkName: "CaseShowChannels" */'@/components/Cases/Context/ChannelsNew'),
                    name: 'CaseChannelsNew',
                    meta: { title: 'Escolha um canal de comunicação' },
                    beforeEnter: RequireAutorization
                  }
                ]
              },
              {
                path: 'propostas',
                component: () => import(/* webpackChunkName: "CaseShowProposal" */'@/components/Cases/Context/Proposals'),
                meta: {title: 'Propostas realizadas'},
                beforeEnter: RequireAutorization,
                children: [
                  {
                    path: '/',
                    component: () => import(/* webpackChunkName: "CaseShowProposal" */'@/components/Cases/Context/ProposalsList'),
                    name: 'CaseProposalsList',
                    meta: { title: 'Propostas realizadas' },
                    beforeEnter: RequireAutorization
                  },
                  {
                    path: ':proposalId/detalhes',
                    name: 'CaseProposalPay',
                    component: () => import(/* webpackChunkName: "CaseShowProposal" */'@/components/Cases/Context/ProposalPay'),
                    meta: {title: 'Detalhes de pagamento'},
                    beforeEnter: RequireAutorization
                  }
                ]
              },
              {
                path: 'anotacoes',
                component: () => import(/* webpackChunkName: "CaseShowNotes" */'@/components/Cases/Context/Notes/Notes'),
                children: [
                  {
                    path: '/',
                    component: () => import(/* webpackChunkName: "CaseShowNotes" */'@/components/Cases/Context/Notes/List'),
                    name: 'CaseNotesList',
                    meta: { title: 'Anotações do caso' },
                    beforeEnter: RequireAutorization
                  },
                  {
                    path: 'criar',
                    name: 'CaseNoteCreate',
                    component: () => import(/* webpackChunkName: "CaseShowNotes" */'@/components/Cases/Context/Notes/Create'),
                    meta: {title: 'Criar Anotação'},
                    beforeEnter: RequireAutorization
                  }
                ]
              },
              {
                path: 'agenda',
                component: () => import(/* webpackChunkName: "CaseShowSchedule" */'@/components/Cases/Context/Schedule'),
                children: [
                  {
                    path: '/',
                    component: () => import(/* webpackChunkName: "CaseShowSchedule" */'@/components/Cases/Context/ScheduleList'),
                    name: 'CaseScheduleList',
                    meta: { title: 'Agenda' },
                    beforeEnter: RequireAutorization
                  },
                  {
                    path: 'criar',
                    name: 'CaseScheduleCreate',
                    component: () => import(/* webpackChunkName: "CaseShowSchedule" */'@/components/Cases/Context/ScheduleCreate'),
                    meta: {title: 'Adicionar na agenda'},
                    beforeEnter: RequireAutorization
                  }
                ]
              },
              {
                path: '*',
                name: 'CaseError',
                component: () => import(/* webpackChunkName: "CaseShowNotFound" */'@/components/ui/ContextNotFound'),
                meta: {title: 'Essa página não existe'}
              }
            ]
          }
        ]
      }
    ]
  }
]
