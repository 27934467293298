import * as types from './types'
import { processData, processRecords, request, searchEnv, apiHost } from '@/utils'
import firebase from '@firebase/app'
import * as algoliasearch from 'algoliasearch'
import i18n from '@/plugins/i18n'
import Query from '@/utils/query'

const algoliaClient = algoliasearch("C3694NAJMM", "d93633997a75e44a4d0cdbc700903e34")

export default {
  INIT() { },
  async [types.GET_LIST]({ commit, firebase, rootGetters }, paginate) {
    const companyId = rootGetters['currentCompany/id']
    const userId = rootGetters['user/getUserInfo']['userId']
    const db = firebase.firestore()
    const pageSize = paginate.rowsPerPage
    const companyRef = db.collection('companies').doc(companyId)

    if (paginate.search.length) {
      const searchCases = algoliaClient.initIndex(`${searchEnv()}:companies:${companyId}:cases`)

      searchCases.search( paginate.search )
        .then(function (responses) {
          console.log(responses.hits)
          commit('setList', responses.hits)
          commit('setLength', responses.nbHits)
        }).catch(error => {
          console.log(error)
        })
    } else {
      let baseQuery = db.collection('cases').where('userPermissions', 'array-contains', userId).orderBy('code', 'desc')
      if (paginate.negotiator) {
        const negotiatorRef = db.collection('users').doc(paginate.negotiator)
        baseQuery = baseQuery.where('administrator', '==', negotiatorRef)
      }
      const query = new Query(baseQuery, commit)
      baseQuery = query.filterByState(paginate.state)
      baseQuery = await query.pageHas(1, paginate.page)
      baseQuery = query.pageNext(paginate.page)
      baseQuery = query.pagePrev(paginate.page)
      baseQuery = baseQuery.limit(pageSize)

      baseQuery.onSnapshot(async snapshots => {
        let records = await processRecords(snapshots)

        const countDoc = !!paginate.state ? '--cases--' : '--counts--'
        const snapCount = await companyRef.collection('stats').doc(countDoc).get()
        const countData = snapCount.data() || {}
        let length = !!paginate.state ? countData[paginate.state] : countData.cases

        records.length && query.paginator.setCurrentPage(paginate.page)
        records.length && query.paginator.setPage(paginate.page, snapshots.docs[0], snapshots.docs[snapshots.docs.length - 1])

        for(const item of records) {
          if(item.state === 'negotiation') {
            const administrator = await item.administrator
            item.administratorName = administrator.data().name
          }
          item.insertedAt = i18n.d(item.insertedAt.toDate(), 'long')
          item.updatedAt = i18n.d(item.updatedAt.toDate(), 'long')
        }

        const recordsCache = paginate.page === 1 ? [] : rootGetters['cases/list']

        commit('setLength', length)
        commit('setList', [...recordsCache, ...records])
        commit('setListLoading', false)
        commit('setListRender', true)
      })
    }
  },
  async [types.GET_REPORT]({ commit, firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    firebase
      .firestore()
      .collection('companies')
      .doc(currentCompany)
      .collection('stats')
      .doc('--cases--')
      .onSnapshot(async doc => {
        const record = await processData(doc)
        commit('setReport', record)
      })
  },
  async [types.GET]({ dispatch, commit, firebase, rootGetters }, id) {
    commit('setRecordLoading', true)
    const isGuest = rootGetters['user/isGuest']
    const isNegotiator = rootGetters['user/isNegotiator']
    const db = firebase.firestore()
    return new Promise(resolve => {
      db
        .collection('cases')
        .doc(id)
        .onSnapshot(async doc => {
          const record = await processData(doc)

          const companySnap = await record.company
          const camaraSnap = await companySnap.data().camara.get()
          const guestSnap = await record.user

          commit('companyName', companySnap.data().fantasyName)
          commit('camaraName', camaraSnap.data().fantasyName)
          commit('guestName', guestSnap.data().name)

          const company = await db.collection('companies').doc(record.companyId).get()
          const eventPromise = await record.event
          const event = await processData(eventPromise)
          record.event = event

          if (isGuest) {
            if (['initialProposal'].includes(record.state)) {
              const proposal = await dispatch('proposal/firstActiveForCase', id, { root: true })
              const company = await proposal.company
              proposal.company = await processData(company)
              proposal.case = record
              commit('proposal/setProposal', proposal, { root: true })
            } else if (['accepted'].includes(record.state)) {
              const proposals = await db.collection('proposals').where('state', '==', 'accepted').where('case', '==', doc.ref).orderBy('insertedAt', 'desc').limit(1).get()
              const proposal = proposals.empty ? { exists: false } : await processData(proposals.docs.first())
              if (!proposals.empty && proposals.docs.first().exists) {
                const bankSlipsSnapshots = await db.collection('bankSlips').where('proposal', '==', proposals.docs.first().ref).get()
                const ticketsPayments = await processRecords(bankSlipsSnapshots)
                ticketsPayments.sort((a, b) => a.dueAt.seconds - b.dueAt.seconds)
                proposal.ticketsPayments = ticketsPayments
                proposal.exists = true
              }
              proposal.exists && commit('proposal/setProposal', proposal, { root: true })
            }
          }
          if (isNegotiator) {
            if (!['mediation', 'accepted', 'refused'].includes(record.state)) {
              await dispatch('proposal/firstActiveForCase', id, { root: true })
            }
          }
          if (record.administratorId) {
            const administrator = await db.collection('users').doc(record.administratorId).get()
            record.administratorName = administrator.data().name
          }

          record.companyName = company.data().fantasyName

          commit('set', record)
          record.userPermissions && dispatch('getParticipants', record.userPermissions)
          commit('setRecordLoading', false)
          commit('setListLoading', false)
          resolve(record)
        })
    })
  },
  async [types.CREATE]({ firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    return request(token).post(`${apiHost()}/case`, data)
  },
  async [types.VIDEOCONFERENCE_IN_PROGRESS]({ commit, firebase, rootGetters }, caseId) {
    const userId = rootGetters['user/getUserInfo']['userId']
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(caseId)

    db
      .collection('tasks')
      .where('participants', 'array-contains', userId)
      .where('case', '==', caseRef)
      .where('state', '==', 'in-progress')
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .onSnapshot(async documentSnapshots => {
        const doc = documentSnapshots.docs[0]

        doc && commit('app/videoConference', { room: doc.id, show: true }, { root: true })
      })
  },
  async [types.VIDEOCONFERENCE_TOKEN]({ rootGetters, firebase }) {
    const identity = rootGetters['user/getUserInfo']['userId']
    const room = rootGetters['app/videoConferenceRoom']
    const token = await firebase.auth().currentUser.getIdToken()

    return request(token).post('/functions/videoToken', { identity, room })
  },
  async [types.VIDEOCONFERENCE_START]({ firebase }, room) {
    return firebase.functions().httpsCallable('videoCreate')({ room })
  },
  async [types.VIDEOCONFERENCE_COMPLETE]({ }, room) {
    return firebase.functions().httpsCallable('videoComplete')({ room })
  },
  async [types.IMPORT]({ }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    return request(token).post(`${apiHost()}/batch`, data, {
      headers: {
        'Content-Type': `multipart/form-data;`,
      }
    })
    // const db = firebase.firestore()

    // const storageRef = firebase.storage().ref()
    // const companyRef = db.collection('companies').doc(data.currentCompany)
    // const eventRef = companyRef.collection('events').doc(data.eventId)
    // const batchRef = db.collection('batches').doc(data.id)

    // const fileParts = data.file.name.split('.')
    // const extension = fileParts[fileParts.length - 1]
    // const path = `companies/${data.currentCompany}/batches/${data.id}.${extension}`
    // const file = data.file

    // return db.runTransaction(async transaction => {
    //   delete data.id
    //   delete data.file
    //   delete data.currentCompany
    //   delete data.eventId

    //   data.company = companyRef
    //   data.event = eventRef
    //   data.path = path
    //   data.insertedAt = new Date()
    //   data.updatedAt = new Date()

    //   await storageRef.child(path).put(file, { contentType: file.type })
    //   transaction.set(batchRef, data)
    // })
  },
  async [types.GET_PARTICIPANTS]({ commit, firebase, rootGetters }, participants) {
    const db = firebase.firestore()
    const administratorId = rootGetters['cases/record']['administratorId']
    participants = participants.filter(item => ![administratorId].includes(item))

    let userPromises = []
    participants.forEach(id => {
      userPromises.push(db.collection('users').doc(id).get())
    })

    Promise.all(userPromises).then(async documentSnapshots => {
      let records = await processRecords(documentSnapshots)
      commit('setParticipants', records)
      commit('setParticipantsLoading', false)
    })

  },
  async [types.GET_TIMELINE]({ commit, firebase, rootGetters }, id) {
    firebase
      .firestore()
      .collection(`cases/${id}/timeline`)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async querySnapshot => {
        let records = await processRecords(querySnapshot)

        records.map(item => {
          item.date = item.insertedAt && i18n.d(item.insertedAt.toDate(), 'date')
          item.time = item.insertedAt && i18n.d(item.insertedAt.toDate(), 'time')
        })

        records = records.groupBy(i => i.date)

        commit('setTimeline', records)
        commit('setTimelineLoading', false)
      })
  },
  async [types.GET_MESSAGES]({ commit, firebase }, id) {
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(id)

    firebase
      .firestore()
      .collection('conversations')
      .where('case', '==', caseRef)
      .orderBy('insertedAt')
      .onSnapshot(async querySnapshot => {
        let records = await processRecords(querySnapshot)

        records.map(item => {
          item.date = item.insertedAt && i18n.d(item.insertedAt.toDate(), 'date')
          item.time = item.insertedAt && i18n.d(item.insertedAt.toDate(), 'time')
        })

        records = records.groupBy(i => i.date)

        commit('setMessages', records)
        commit('setMessagesLoading', false)
      })
  },
  async [types.SEND_MESSAGE]({ firebase }, message) {
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(message.caseId)
    const user = db.collection('users').doc(message.userId)
    const insertedAt = firebase.firestore.FieldValue.serverTimestamp()
    const id = message.id
    const isBot = message.isBot
    delete message.id
    delete message.caseId
    delete message.userId
    delete message.isBot

    const record = {
      data: {
        message: message.content,
        isBot,
        input: false,
        options: {}
      },
      type: 'Message',
      userPermissions: message.userPermissions,
      usersUnread: message.usersUnread
    }

    return db
      .collection('conversations')
      .doc(id)
      .set({ ...record, user, insertedAt, case: caseRef, slug: message.slug })
  },
  async [types.GET_NOTES]({ commit, firebase }, id) {
    firebase
      .firestore()
      .collection(`cases/${id}/notes`)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async querySnapshot => {
        let records = await processRecords(querySnapshot)

        records.map(item => {
          item.insertedAt = i18n.d(item.insertedAt.toDate(), 'long')
          return item
        })

        commit('setNotes', records)
        commit('setNotesLoading', false)
      })
  },
  async [types.GET_DOCUMENTS]({ commit, firebase }, id) {
    firebase
      .firestore()
      .collection(`cases/${id}/documents`)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async querySnapshot => {
        let records = await processRecords(querySnapshot)

        records.map(item => {
          item.insertedAt = i18n.d(item.insertedAt.toDate(), 'short_datetime')
          return item
        })

        commit('setDocuments', records)
        commit('setDocumentsLoading', false)
      })
  },
  async [types.CREATE_NOTE]({ firebase, rootGetters }, { id, note }) {
    const db = firebase.firestore()
    const userId = rootGetters['user/getUserInfo']['userId']
    const user = db.collection('users').doc(userId)
    const caseRecord = rootGetters['cases/record']
    const insertedAt = firebase.firestore.FieldValue.serverTimestamp()

    return db
      .collection(`cases/${caseRecord.id}/notes`)
      .doc(id)
      .set({ note, insertedAt, user })
  },
  async [types.GET_TASKS]({ commit, firebase }, id) {
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(id)

    db
      .collection('tasks')
      .where('case', '==', caseRef)
      .orderBy('start')
      .onSnapshot(async documentsSnapshot => {
        let records = await processRecords(documentsSnapshot)

        records.map(item => {
          item.start = i18n.d(item.start.toDate(), 'short_datetime')
          return item
        })

        commit('setTasks', records)
        commit('setTasksLoading', false)
      })
  },
  async [types.CREATE_TASK]({ firebase }, task) {
    const db = firebase.firestore()
    const id = task.id
    delete task.id

    task.insertedAt = new Date()
    task.updatedAt = new Date()
    task.case = db.collection('cases').doc(task.case)
    task.owner = db.collection('users').doc(task.owner)

    return db.collection('tasks').doc(id).set(task)
  },
  async goToNegotiate({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/case/goToNegotiate`, data)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(data)
        })
    })
  },
  async [types.REMOVE_TICKET]({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/tickets/remove`, data)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(data)
        })
    })
  },
  async [types.ADD_TICKETS]({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/tickets/add`, data)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(data)
        })
    })
  },
  async [types.UPDATE_TICKET]({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()

    commit('app/setLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      request(token)
        .post(`${apiHost()}/tickets/update`, data)
        .then(data => {
          commit('app/setLoading', false, { root: true })
          resolve(data)
        })
        .catch(err => {
          commit('app/setLoading', false, { root: true })
          reject(data)
        })
    })
  },
  async [types.UPDATE_RECIPIENT_EMAIL]({ commit, firebase }, data) {
    commit('setRecordLoading', true)
    return firebase.functions().httpsCallable('caseUpdateRecipientEmail')(data).finally(() => {
      commit('setRecordLoading', false)
    })
  },
  async [types.UPDATE_RECIPIENT_PHONES]({ commit, firebase }, data) {
    commit('setRecordLoading', true)
    return firebase.functions().httpsCallable('caseUpdateRecipientPhones')(data).finally(() => {
      commit('setRecordLoading', false)
    })
  },
  async [types.UPDATE_RECIPIENT_ADDRESS]({ commit, firebase }, data) {
    commit('setRecordLoading', true)
    return firebase.functions().httpsCallable('caseUpdateRecipientAddress')(data).finally(() => {
      commit('setRecordLoading', false)
    })
  },
  async [types.DROP]({ commit, firebase }, data) {
    const token = await firebase.auth().currentUser.getIdToken()
    const caseId = data.caseId
    delete data.caseId
    return request(token).post(`${apiHost()}/case/${caseId}/drop`, data)
  }
}
