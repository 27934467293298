import * as types from './types'
import { processRecords } from '@/utils'
import i18n from '@/plugins/i18n'

export default {
  INIT ({ commit, firebase }) {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']
    const ownerId = rootGetters['user/getUserInfo']['userId']
    const owner = firebase.firestore().collection('users').doc(ownerId)

    firebase
      .firestore()
      .collection('tasks')
      .where('owner', '==', owner)
      .onSnapshot(async documentsSnapshot => {
        const records = await processRecords(documentsSnapshot)
        const optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }

        records.map(item => {
          item.startAt = item.start.toDate()
          item.start = item.start.toDate().toLocaleDateString('pt-BR', optionsDate)
          item.end = item.end.toDate().toLocaleDateString('pt-BR', optionsDate)

          item.insertedAt = i18n.d(item.insertedAt.toDate(), 'long')
          item.updatedAt = i18n.d(item.updatedAt.toDate(), 'long')
          return item
        })

        commit('setList', records)
      })
  }
}
