import anime from 'animejs'
import firebase from '@firebase/app'
import { mapGetters } from 'vuex'

import axios from 'axios'

export const request = (token) => {
  return axios.create({
    responseType: 'json',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  })
}

export const searchEnv = () => {
  switch(location.hostname) {
    case 'localhost':
      return 'staging'
    case 'staging.modera.app':
      return 'staging'
    default:
      return 'production'
  }
}

export const apiHost = () => {
  switch(location.hostname) {
    case 'localhost':
      return 'http://localhost:3000'
    case 'staging.modera.app':
      return 'https://api.staging.modera.app'
    default:
      return 'https://api.modera.app'
  }
}

export const processData = doc => {
  doc = { ...doc.data(), id: doc.id, path: doc.ref.path }
  if (doc instanceof firebase.firestore.DocumentReference) {
    doc.ref = doc.ref
  }
  for (const p in doc) {
    if (doc[p] instanceof firebase.firestore.DocumentReference) {
      doc[`${p}Id`] = doc[p].id
      doc[`${p}Path`] = doc[p].path
      doc[p] = doc[p].get()
    }
  }
  return doc
}

export const asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const processRecords = async querySnapshot => {
  let records = []
  let docs = []

  querySnapshot.forEach(async doc => docs.push(doc))

  await asyncForEach(docs, async doc => records.push(await processData(doc)))

  return records
}

export const USER_ID = 'user-id'
export const AUTH_TOKEN = 'token'
export const GROUPS = 'groups'

export const states = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RO',
  'RS',
  'RR',
  'SC',
  'SE',
  'SP',
  'TO'
]


const _MS_PER_DAY = 1000 * 60 * 60 * 24

export const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export const commons = (Vue) => {
  Vue.mixin({
    computed: {
      ...mapGetters('user', ['getGroups']),
      userGroups: {
        get () {
          return this.$store.state.user.groups.map(group => group.name).join(',')
        },
        set (value) {
          this.$store.commit('user/setGroups', value)
        }
      }
    },
    methods: {
      log (variable) {
        console.log(variable)
      }
    }
  })
}

export const logout = {
  methods: {
    logout () {
      this.$store.dispatch('app/userLogout')
    }
  }
}

export const daysPayment = [
  { label: 'Dia 10', value: 10 },
  { label: 'Dia 20', value: 20 },
  { label: 'Dia 30', value: 30 }
]

export const channels = {
  email: {
    send: false,
    hasPeal: false,
    peal: [{
      value: 3,
      template: 'caseInvitePealOne'
    },
    {
      value: 5,
      template: 'caseInvitePealTwo'
    },
    {
      value: 7,
      template: 'caseInvitePealThree'
    }]
  },
  sms: {
    send: false,
    hasPeal: false,
    after: 1,
    peal: [{
      value: 3,
      template: 'caseInvitePealOne'
    },
    {
      value: 5,
      template: 'caseInvitePealTwo'
    },
    {
      value: 7,
      template: 'caseInvitePealThree'
    }]
  },
  letter: {
    send: false,
    after: 5
  }
}

export const timeHour = [
  { value: '00:00', disabled: false },
  { value: '00:15', disabled: false },
  { value: '00:30', disabled: false },
  { value: '00:45', disabled: false },
  { value: '01:00', disabled: false },
  { value: '01:15', disabled: false },
  { value: '01:30', disabled: false },
  { value: '01:45', disabled: false },
  { value: '02:00', disabled: false },
  { value: '02:15', disabled: false },
  { value: '02:30', disabled: false },
  { value: '02:45', disabled: false },
  { value: '03:00', disabled: false },
  { value: '03:15', disabled: false },
  { value: '03:30', disabled: false },
  { value: '03:45', disabled: false },
  { value: '04:00', disabled: false },
  { value: '04:15', disabled: false },
  { value: '04:30', disabled: false },
  { value: '04:45', disabled: false },
  { value: '05:00', disabled: false },
  { value: '05:15', disabled: false },
  { value: '05:30', disabled: false },
  { value: '05:45', disabled: false },
  { value: '06:00', disabled: false },
  { value: '06:15', disabled: false },
  { value: '06:30', disabled: false },
  { value: '06:45', disabled: false },
  { value: '07:00', disabled: false },
  { value: '07:15', disabled: false },
  { value: '07:30', disabled: false },
  { value: '07:45', disabled: false },
  { value: '08:00', disabled: false },
  { value: '08:15', disabled: false },
  { value: '08:30', disabled: false },
  { value: '08:45', disabled: false },
  { value: '09:00', disabled: false },
  { value: '09:15', disabled: false },
  { value: '09:30', disabled: false },
  { value: '09:45', disabled: false },
  { value: '10:00', disabled: false },
  { value: '10:15', disabled: false },
  { value: '10:30', disabled: false },
  { value: '10:45', disabled: false },
  { value: '11:00', disabled: false },
  { value: '11:15', disabled: false },
  { value: '11:30', disabled: false },
  { value: '11:45', disabled: false },
  { value: '12:00', disabled: false },
  { value: '12:15', disabled: false },
  { value: '12:30', disabled: false },
  { value: '12:45', disabled: false },
  { value: '13:00', disabled: false },
  { value: '13:15', disabled: false },
  { value: '13:30', disabled: false },
  { value: '13:45', disabled: false },
  { value: '14:00', disabled: false },
  { value: '14:15', disabled: false },
  { value: '14:30', disabled: false },
  { value: '14:45', disabled: false },
  { value: '15:00', disabled: false },
  { value: '15:15', disabled: false },
  { value: '15:30', disabled: false },
  { value: '15:45', disabled: false },
  { value: '16:00', disabled: false },
  { value: '16:15', disabled: false },
  { value: '16:30', disabled: false },
  { value: '16:45', disabled: false },
  { value: '17:00', disabled: false },
  { value: '17:15', disabled: false },
  { value: '17:30', disabled: false },
  { value: '17:45', disabled: false },
  { value: '18:00', disabled: false },
  { value: '18:00', disabled: false },
  { value: '18:30', disabled: false },
  { value: '18:45', disabled: false },
  { value: '19:00', disabled: false },
  { value: '19:15', disabled: false },
  { value: '19:30', disabled: false },
  { value: '19:45', disabled: false },
  { value: '20:00', disabled: false },
  { value: '20:15', disabled: false },
  { value: '20:30', disabled: false },
  { value: '20:45', disabled: false },
  { value: '21:00', disabled: false },
  { value: '21:15', disabled: false },
  { value: '21:30', disabled: false },
  { value: '21:45', disabled: false },
  { value: '22:00', disabled: false },
  { value: '22:15', disabled: false },
  { value: '22:30', disabled: false },
  { value: '22:45', disabled: false },
  { value: '23:15', disabled: false },
  { value: '23:00', disabled: false },
  { value: '23:30', disabled: false },
  { value: '23:45', disabled: false }
]

export const timeHour2 = [
  { value: '00:00', disabled: false },
  { value: '00:15', disabled: false },
  { value: '00:30', disabled: false },
  { value: '00:45', disabled: false },
  { value: '01:00', disabled: false },
  { value: '01:15', disabled: false },
  { value: '01:30', disabled: false },
  { value: '01:45', disabled: false },
  { value: '02:00', disabled: false },
  { value: '02:15', disabled: false },
  { value: '02:30', disabled: false },
  { value: '02:45', disabled: false },
  { value: '03:00', disabled: false },
  { value: '03:15', disabled: false },
  { value: '03:30', disabled: false },
  { value: '03:45', disabled: false },
  { value: '04:00', disabled: false },
  { value: '04:15', disabled: false },
  { value: '04:30', disabled: false },
  { value: '04:45', disabled: false },
  { value: '05:00', disabled: false },
  { value: '05:15', disabled: false },
  { value: '05:30', disabled: false },
  { value: '05:45', disabled: false },
  { value: '06:00', disabled: false },
  { value: '06:15', disabled: false },
  { value: '06:30', disabled: false },
  { value: '06:45', disabled: false },
  { value: '07:00', disabled: false },
  { value: '07:15', disabled: false },
  { value: '07:30', disabled: false },
  { value: '07:45', disabled: false },
  { value: '08:00', disabled: false },
  { value: '08:15', disabled: false },
  { value: '08:30', disabled: false },
  { value: '08:45', disabled: false },
  { value: '09:00', disabled: false },
  { value: '09:15', disabled: false },
  { value: '09:30', disabled: false },
  { value: '09:45', disabled: false },
  { value: '10:00', disabled: false },
  { value: '10:15', disabled: false },
  { value: '10:30', disabled: false },
  { value: '10:45', disabled: false },
  { value: '11:00', disabled: false },
  { value: '11:15', disabled: false },
  { value: '11:30', disabled: false },
  { value: '11:45', disabled: false },
  { value: '12:00', disabled: false },
  { value: '12:15', disabled: false },
  { value: '12:30', disabled: false },
  { value: '12:45', disabled: false },
  { value: '13:00', disabled: false },
  { value: '13:15', disabled: false },
  { value: '13:30', disabled: false },
  { value: '13:45', disabled: false },
  { value: '14:00', disabled: false },
  { value: '14:15', disabled: false },
  { value: '14:30', disabled: false },
  { value: '14:45', disabled: false },
  { value: '15:00', disabled: false },
  { value: '15:15', disabled: false },
  { value: '15:30', disabled: false },
  { value: '15:45', disabled: false },
  { value: '16:00', disabled: false },
  { value: '16:15', disabled: false },
  { value: '16:30', disabled: false },
  { value: '16:45', disabled: false },
  { value: '17:00', disabled: false },
  { value: '17:15', disabled: false },
  { value: '17:30', disabled: false },
  { value: '17:45', disabled: false },
  { value: '18:00', disabled: false },
  { value: '18:00', disabled: false },
  { value: '18:30', disabled: false },
  { value: '18:45', disabled: false },
  { value: '19:00', disabled: false },
  { value: '19:15', disabled: false },
  { value: '19:30', disabled: false },
  { value: '19:45', disabled: false },
  { value: '20:00', disabled: false },
  { value: '20:15', disabled: false },
  { value: '20:30', disabled: false },
  { value: '20:45', disabled: false },
  { value: '21:00', disabled: false },
  { value: '21:15', disabled: false },
  { value: '21:30', disabled: false },
  { value: '21:45', disabled: false },
  { value: '22:00', disabled: false },
  { value: '22:15', disabled: false },
  { value: '22:30', disabled: false },
  { value: '22:45', disabled: false },
  { value: '23:15', disabled: false },
  { value: '23:00', disabled: false },
  { value: '23:30', disabled: false },
  { value: '23:45', disabled: false }
]

export const timeDuration = [
  { value: '00:30', disabled: false },
  { value: '00:45', disabled: false },
  { value: '01:00', disabled: false }
]

export const animeMixin = (Vue) => {
  Vue.mixin({
    data () {
      return {
        anime
      }
    }
  })
}
