import * as types from './types'

export default {
  [types.SET_LENGTH] (state, payload) {
    state.length = payload
  },
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
  [types.SET_LIST_RENDER] (state, payload) {
    state.listRender = payload
  },
  [types.FIRST_ACTIVE] (state, payload) {
    state.firstActive = payload
  },
  [types.LAST_FOR_CASE] (state, payload) {
    state.lastForCase = payload
  },
  [types.FIRST_ACTIVE_FOR_CASE] (state, payload) {
    state.firstActiveForCase = payload
  },
  [types.FIRST_ACTIVE_LOADING] (state, payload) {
    state.firstActiveLoading = payload
  },
  [types.SET_LIST_BY_CASE] (state, payload) {
    state.listByCase = payload
  },
  [types.SET_LIST_BY_CASE_LOADING] (state, payload) {
    state.listByCaseLoading = payload
  },
  [types.SET_PROPOSAL] (state, data) {
    let proposal = data
    let label = proposal.installmentSelected.parcel === 1 ? 'à vista' : proposal.installmentSelected.parcel
    let parcelLabel = proposal.installmentSelected.parcel === 1 ? 'à vista' : `${proposal.installmentSelected.parcel} vezes`
    let rangeValue = {
      label: label,
      parcel: proposal.installmentSelected.parcel,
      parcelLabel: parcelLabel,
      parcelValue: proposal.installmentSelected.parcelValue,
      total: proposal.installmentSelected.currentValue
    }

    let range = proposal.installments.map(installment => {
      let label = installment.parcel === 1 ? 'à vista' : installment.parcel
      let parcelLabel = installment.parcel === 1 ? 'à vista' : `${installment.parcel} vezes`

      return {
        label: label,
        parcelLabel: parcelLabel,
        parcel: installment.parcel,
        parcelValue: installment.parcelValue,
        total: installment.currentValue,
        isHide: true
      }
    }).sort((a, b) => a.parcel - b.parcel)

    state.slider.value = proposal.installmentSelected.parcel
    state.slider.range = range
    state.slider.max = range.length
    state.slider.data = range.map(item => item.parcel)
    state.rangeValue = rangeValue
    state.range = range
    state.record = data
  },
  [types.GET_SIMULATED_RENEGOTIATION] (state, data) {
    let proposal = JSON.parse(JSON.stringify(data))
    let label = proposal.installmentSelected.parcel === 1 ? 'à vista' : proposal.installmentSelected.parcel
    let parcelLabel = proposal.installmentSelected.parcel === 1 ? 'à vista' : `${proposal.installmentSelected.parcel} vezes`
    let rangeValue = {
      label: label,
      parcel: proposal.installmentSelected.parcel,
      parcelLabel: parcelLabel,
      parcelValue: proposal.installmentSelected.parcelValue,
      total: proposal.installmentSelected.currentValue
    }

    let range = proposal.installments.map(installment => {
      let label = installment.parcel === 1 ? 'à vista' : installment.parcel
      let parcelLabel = installment.parcel === 1 ? 'à vista' : `${installment.parcel} vezes`

      return {
        label: label,
        parcelLabel: parcelLabel,
        parcel: installment.parcel,
        parcelValue: installment.parcelValue,
        total: installment.currentValue,
        isHide: true
      }
    }).sort((a, b) => a.parcel - b.parcel)

    proposal.slider = {}
    proposal.slider.value = proposal.installmentSelected.parcel
    proposal.slider.range = range
    proposal.slider.max = range.length
    proposal.slider.data = range.map(item => item.parcel)
    proposal.rangeValue = rangeValue
    proposal.range = range
    state.simulated_renegotiation = proposal
  }
}
