import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        primary: '#000000',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    },
    options: { customProperties: true }
  },
  iconfont: 'mdi',
  lang: {
    locales: { pt },
    current: 'pt'
  }
}

Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur()
  }
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

export default new Vuetify(options)
