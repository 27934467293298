export default {
  list (state) {
    return state.list
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  listRender (state) {
    return state.listRender
  },
  page (state) {
    return state.page
  },
  startAt (state) {
    return state.startAt
  },
  startAfter (state) {
    return state.startAfter
  }
}
