<template>
  <v-dialog
    v-if="modal"
    content-class="sideContent ma-0 d-flex"
    :value="true"
    transition="fade-transition"
    persistent
    no-click-animation
    :width="width">
    <v-card color="#F2F2F7">
      <v-app-bar
        class="elevation-1"
        color="primary"
        height="50px"
        clipped-left
        dark>
        <template v-if="!isSubcontext">
          <h2 class="body-1 font-weight-bold" data-cy="sidebar-content-title">
            {{ title }}
          </h2>
          <v-spacer />
          <v-btn
            class="btn-close mr-n1"
            data-cy="close"
            dark
            icon
            small
            @click.native="close">
            <v-icon>{{ $root.icons.action.close }}</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            class="btn-close ml-n1"
            data-cy="close"
            dark
            icon
            small
            @click.native="back">
            <v-icon>{{ $root.icons.action.back }}</v-icon>
          </v-btn>
          <h2 class="body-1 font-weight-bold ml-2" data-cy="sidebar-content-title">
            {{ title }}
          </h2>
        </template>
      </v-app-bar>
      <perfect-scrollbar class="sideContentScroll pr-5" :options="options">
        <v-card-text class="pa-0">
          <v-container
            grid-list-xs
            class="my-0 pb-0 sidebar-context">
            <v-layout
              v-resize="onResize"
              row
              wrap>
              <slot />
            </v-layout>
          </v-container>
        </v-card-text>
      </perfect-scrollbar>
      <v-card-actions class="px-4">
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-container
    v-else
    grid-list-lg
    :style="cssVars"
    class="pt-0 pr-0 pl-3 sideContentContext">
    <v-card color="#F2F2F7" flat>
      <v-card-title class="sideHeader subtitle-1 font-weight-bold text--primary pt-0 pb-3 px-0 pr-5">
        <slot name="header" />
      </v-card-title>

      <v-card-text class="pa-0">
        <perfect-scrollbar class="sideContentPs pr-5" :options="options" @ps-y-reach-end="endContent" @ps-scroll-up="inContent">
          <v-container
            grid-list-xs
            class="my-0 pb-0 pt-4 px-0">
            <slot />
          </v-container>
        </perfect-scrollbar>
      </v-card-text>

      <v-card-actions class="pa-0 pr-5">
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import resizing from '@/mixins/resizing'
  import validate from '@/mixins/validate'
  import Modal from '@/components/ui/Modal'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SideContent',
    components : { Modal },
    mixins: [ resizing, validate ],
    props: {
      title: {
        type: String,
        default: '',
        require: true
      },
      width: {
        type: String,
        default: '316'
      },
      modal: {
        type: Boolean,
        default: false
      },
      heightContent: {
        type: String,
        default: '160px'
      },
      isSubcontext: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      options: { suppressScrollX: true, wheelPropagation: false }
    }),
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      }),
      cssVars() {
        return {
          '--height': this.heightContent
        }
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      back () {
        this.$emit('back')
      },
      inContent () {
        this.$emit('in-content')
      },
      endContent () {
        this.$emit('end-content')
      }
    }
  }
</script>


<style lang="sass">
  // .sideContentScroll
  //   height: calc(100vh - 126px)  !important
  .sideContentPs
    height: calc(100vh - var(--height)) !important
    // .ps__rail-y
    //   right: -20px !important
  .sideHeader
    .v-btn--fab.v-size--x-small
      height: 22px
      min-width: 22px
      .v-icon
        height: 16px
        font-size: 16px
        min-width: 16px
  .v-dialog.sideContent
    max-height: initial !important
    height: calc(100vh - 40px)
    position: absolute
    right: 20px
    border-radius: 20px
    .v-card
      height: 100%
      .v-card__title
        color: #ffffff
      .v-card__actions ~ .ps
        height: calc(100vh - 126px)
        margin: 0
        color: #000
        .v-card__text
          background: #ffffff
          height: calc(100% - 127px)
      .v-card__actions
        height: 74px
    #ContextualMenu
      width: 80px

  .v-navigation-drawer
    &.sidedar-content
      overflow: hidden
      z-index: 11

    .area-scroll
      overflow-y: auto
      height: 100%

  .btn-close
    background: rgba(255,255,255, .1)

  .sidebar-context
    &.container.grid-list-xs
      padding: 2px

  .ps
    margin-top: 0px
    height: calc(100vh - 170px)
</style>
