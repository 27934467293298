import { get } from '@/utils/vuex'

export default {
  length (state) {
    return state.length
  },
  list (state) {
    return state.list
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  listByCase (state) {
    return state.listByCase
  },
  listByCaseLoading (state) {
    return state.listByCaseLoading ? 1 : 0
  },
  listRender (state) {
    return state.listRender
  },
  record: get('record'),
  recordLoading: get('recordLoading')
}
