/* globals: localStorage */
import router from '@/router'
import { processData } from '@/utils'
import axios from 'axios'
import { AUTH_TOKEN } from '@/utils/index'
const token = localStorage.getItem(AUTH_TOKEN)

const request = axios.create({
  responseType: 'json',
  headers: {
    Authorization: token ? `Bearer ${token}` : ''
  }
})

const setState = (state, last_changed) => ({ state, last_changed })

const rtdb_presence = (firebase, router) => {
  const uid = firebase.auth().currentUser.uid
  const userStatusDatabaseRef = firebase.database().ref('/users/' + uid)

  const isOfflineForDatabase = setState('offline', firebase.database.ServerValue.TIMESTAMP)
  const isOnlineForDatabase = setState('online', firebase.database.ServerValue.TIMESTAMP)

  firebase.database().ref('.info/connected').on('value', function(snapshot) {
    if (snapshot.val() == false) { return }

    userStatusDatabaseRef
      .onDisconnect()
      .set(isOfflineForDatabase)
      .then(() => userStatusDatabaseRef.update({ ...isOnlineForDatabase, router }))
  })
}

const actions = {
  auth ({ commit, firebase }) {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const db = firebase.firestore()
          const result = await firebase.auth().currentUser.getIdTokenResult()
          localStorage.setItem('token', result.token)

          db
            .collection('users')
            .doc(result.claims.user_id)
            .onSnapshot(async doc => {
              const userData = doc.data()
              commit('user/setToken', result.token, { root: true })
              commit('user/setGroups', result.claims.groups, { root: true })
              commit('setAbilities', userData.abilities)
              commit('user/setUserId', doc.id, { root: true })
              commit('user/setEmail', userData.email, { root: true })
              commit('user/setName', userData.name, { root: true })
              commit('user/setDocument', userData.document, { root: true })
              commit('user/setBirthDate', userData.birthDate, { root: true })
              commit('user/setPhotoURL', userData.photoURL, { root: true })

              if (result.claims.currentCompany) {
                commit('currentCompany/setId', result.claims.currentCompany, { root: true })

                db
                  .collection('companies')
                  .doc(result.claims.currentCompany)
                  .onSnapshot(async doc => {
                    doc.ref.collection('stats').doc('--counts--').onSnapshot(async counts => {
                      commit('currentCompany/setCurrentCompanyCounts', counts.data(), { root: true })

                      const currentCompany = await processData(doc)
                      commit('currentCompany/setCurrentCompany', currentCompany, { root: true })
                      const companiesAbilities = userData.companiesAbilities || {}
                      const companyId = currentCompany.id
                      const companyAbility = companiesAbilities[companyId] || {}
                      commit('setCompanyAbilities', companyAbility)

                      resolve(true)
                    })
                  })
              } else {
                resolve(true)
              }
            })

        } else {
          commit('setAbilities', [])
          commit('setIsAuthenticated', false)
          commit('setLoading', false)
          !RegExp(/auth|boleto/).test(location.pathname) && router.push({ name: 'Login' })
          resolve(false)
        }
      })
    })
  },

  async load ({ commit, dispatch, rootGetters }) {
    const currentCompany = rootGetters['currentCompany/id']

    if (!currentCompany) {
      return dispatch('INIT')
    } else {
      return Promise.resolve()
    }
  },
  async presence ({ firebase }, route) {
    const router = {
      name: route.name,
      params: route.params,
      path: route.path
    }
    rtdb_presence(firebase, router)
  },
  getSettings ({ commit }, settings) {
    commit('setSettings', settings)
  },
  getHosts ({ commit }, hosts) {
    commit('setHosts', hosts)
  },
  getTheme ({ commit }, theme) {
    commit('setTheme', theme)
  },
  videoConference ({ commit }, payload) {
    commit('videoConference', payload)
  },
  getLoading ({ commit, state }) {
    commit('setLoading', state.isLoading)
  },
  async userLogin ({ commit, firebase }, { email, password }) {
    let auth = firebase.auth()
    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await auth.signInWithEmailAndPassword(email, password)
      setTimeout(_ => router.push('/'), 400)
    } catch (error) {
      throw error
    } finally {
      commit('setIsAuthenticated', false)
      commit('setLoading', false)
    }
  },
  userLogout ({ commit, firebase }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        commit('setIsAuthenticated', false)
        router.push({ name: 'Login' })
      })
      .catch(() => {
        commit('setIsAuthenticated', false)
        router.push('/')
      })
  },
  inviteCode ({ commit, firebase }, credentials) {
    return request.post('/functions/customToken', credentials).then(res => {
      return new Promise((resolve, reject) => {
        if (res.status === 200) {
          firebase
            .auth()
            .signInWithCustomToken(res.data.token)
            .then(resolve)
            .catch(reject)
        } else {
          reject()
        }
      })
    })
  }
}

export default actions
