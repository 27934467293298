import { Ability, AbilityBuilder } from '@casl/ability'

export const ability = new Ability()

export const abilityPlugin = (store) => {
  ability.update(store.getters['user/getGroups'])

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'user/setGroups':
        const { rules, can } = AbilityBuilder.extract()
        let groups = store.getters['user/getGroups']
        let groupNames = groups.map(group => group.name)
        let groupsJoins = groupNames.join(',')
        let groupsConcat = groups.reduce((acc, group) => acc.concat(group.abilities), [])
        let uniqueItems = Array.from(new Set(groupsConcat))

        can(uniqueItems, groupsJoins)

        ability.update(rules)
        break
    }
  })
}
