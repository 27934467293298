<template>
  <div
    class="wrapper-loading"
    :class="className">
    <!-- <img src="@/assets/images/loading.png" :style="setStyle"  :width="size" class="loading" alt="loading animado"> -->
    <div class="orbit-spinner">
      <div class="orbit" />
      <div class="orbit" />
      <div class="orbit" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: [String, Number],
        default: '200px'
      },
      full: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      setStyle () {
        const style = ` left: calc(50% - (${this.size}/2));
                        top: calc(50% - (${this.size}/2));
                      `
        return style
      },
      className () {
        return this.full ? 'is-full' : ''
      }
    }
  }
</script>

<style lang="sass">
  @import '../../assets/style/accept-terms'

  $larger: 3px
  $timer: 1500ms

  // @keyframes loading
  //   0%
  //     transform: rotate(0deg) scale(0.3)
  //     opacity: 1

  //   50%
  //     transform: rotate(180deg) scale(0.4)
  //     opacity: 0.2

  //   100%
  //     transform: rotate(360deg) scale(0.3)
  //     opacity: 1

  .wrapper-loading

    // .loading
    //   animation: loading 2s 1 0s linear
    //   animation-iteration-count: infinite
    //   transform: translate(-50%, -50%)
    //   position: absolute

    &.is-full
      background: $bgColor
      position: fixed
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: 10

    .orbit-spinner, .orbit-spinner *
      box-sizing: border-box

    .orbit-spinner
      height: 55px
      width: 55px
      border-radius: 50%
      perspective: 800px
      position: absolute
      transform: translate(-50%, -50%)
      left: 50%
      top: 50%

      .orbit
        position: absolute
        box-sizing: border-box
        width: 100%
        height: 100%
        border-radius: 50%
        &:nth-child(1)
          left: 0%
          top: 0%
          animation: orbit-spinner-orbit-one-animation $timer linear infinite
          border-bottom: $larger solid $primary
        &:nth-child(2)
          right: 0%
          top: 0%
          animation: orbit-spinner-orbit-two-animation $timer linear infinite
          border-right: $larger solid $secondary
        &:nth-child(3)
          right: 0%
          bottom: 0%
          animation: orbit-spinner-orbit-three-animation $timer linear infinite
          border-top: $larger solid $accent

  @keyframes orbit-spinner-orbit-one-animation
    0%
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg)
    100%
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg)

  @keyframes orbit-spinner-orbit-two-animation
    0%
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg)
    100%
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg)

  @keyframes orbit-spinner-orbit-three-animation
    0%
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg)
    100%
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)

</style>
