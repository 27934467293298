import { RequireAutorization } from './routerAuth'

export const ROUTE_PROPOSALS = [
  {
    name: 'Negotiations',
    path: '/acordos',
    component: () => import(/* webpackChunkName: "proposal" */'@/components/pages/Negotiations'),
    meta: {title: 'Propostas'},
    beforeEnter: RequireAutorization,
  }
]
