import * as types from './types'

const mutations = {
  'setCurrentCompany' (state, payload) {
    state.id = payload.id
    state.fantasyName = payload.fantasyName
    state.calculateParams = payload.calculateParams
    state.onboarding = payload.onboarding
    state.plan = payload.plan
    state.channels = payload.channels
    state.payment = payload.payment
  },
  'setId' (state, payload) {
    state.id = payload
  },
  'setCurrentCompanyCounts' (state, payload) {
    state.countTotal = payload
  },
  responsibleId (state, payload) {
    Object.assign(state.responsibleId, payload)
  },
  [types.SET_DASHBOARD_PANEL_RESULT] (state, payload) {
    state.dasboardPanelResults = payload
  },
  [types.SET_DASHBOARD_ACCEPTED] (state, payload) {
    state.dasboardAccepted = payload
  },
  [types.SET_DASHBOARD_CASES_COUNT] (state, payload) {
    state.dasboardCasesCount = payload
  },
  [types.SET_DASHBOARD_INVITES] (state, payload) {
    state.dasboardInvites = payload
  }
}

export default mutations
