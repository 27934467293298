const state = {
  list: [],
  listLoading: true,
  groups: [],
  user: {
    currentCompany: null,
    name: null,
    email: null,
    token: null,
    userId: null
  }
}

export default state
