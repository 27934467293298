import * as types from './types'
import { processRecords } from '@/utils'

export default {
  INIT ({}) {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }) {
    const userId = rootGetters['user/getUserInfo']['userId']

    firebase
      .firestore()
      .collection('conversations')
      .where('usersUnread', 'array-contains', userId)
      .orderBy('insertedAt', 'desc')
      .limit(10)
      .onSnapshot(async querySnapshot => {
        let records = await processRecords(querySnapshot)

        records.map(item => {
          item.insertedAtDate = item.insertedAt.toDate()
          return item
        })

        commit('setList', records)
        commit('setListLoading', false)
      })
  },
  async [types.READ] ({ firebase, rootGetters }, messages) {
    const userId = rootGetters['user/getUserInfo']['userId']
    const db = firebase.firestore()
    const batch = db.batch()

    messages.forEach(message => {
      const usersUnread = message.usersUnread.filter(item => item !== userId)
      const messageRef = db.collection('conversations').doc(message.id)
      batch.update(messageRef, {usersUnread: usersUnread})
    })

    batch.commit()
  }
}
