import * as types from './types'
import { processData, processRecords, request, searchEnv } from '@/utils'
import firebase from '@firebase/app'
import i18n from '@/plugins/i18n'
import Query from '@/utils/query'

export default {
  INIT () {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }, paginate) {
    const currentCompany = rootGetters['currentCompany/id']
    const userId = rootGetters['user/getUserInfo']['userId']
    const db = firebase.firestore()
    const pageSize = paginate.rowsPerPage
    const companyRef = db.collection('companies').doc(currentCompany)
    const stats = await companyRef.collection('stats').doc('--cases--').get()
    let baseQuery = db.collection('terms').where('company', '==', companyRef).where('userPermissions', 'array-contains', userId).where('state', '==', 'extinction').orderBy('insertedAt', 'desc')

    const query = new Query(baseQuery, commit)
    baseQuery = await query.pageHas(1, paginate.page)
    baseQuery = query.pageNext(paginate.page)
    baseQuery = query.pagePrev(paginate.page)
    baseQuery = baseQuery.limit(pageSize)

    baseQuery.onSnapshot(async snapshots => {
      let records = await processRecords(snapshots)
      records.length && query.paginator.setCurrentPage(paginate.page)
      records.length && query.paginator.setPage(paginate.page, snapshots.docs[0], snapshots.docs[snapshots.docs.length - 1])

      records.map(item => {
        item.insertedAt = i18n.d(item.insertedAt.toDate(), 'long')
        return item
      })

      commit('setLength', stats.data().dropped)
      commit('setList', records)
      commit('setListLoading', false)
      commit('setListRender', true)
    })
  }
}
