<template>
  <v-app
    :style="cssVariables"
    :class="{ 'hide-menu': setHideMenuClass }">
    <loading
      v-if="isShowLoading"
      full />

    <transition
      name="fade"
      mode="out-in"
      appear>
      <router-view />
    </transition>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :multi-line="snackbar.multiline"
      :vertical="snackbar.vertical"
      :top="snackbar.top"
      :bottom="snackbar.bottom"
      :left="snackbar.left"
      :right="snackbar.right">
      <span v-html="snackbar.text" />
      <v-btn
        text
        @click.native="snackbar.show = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import snackbar from '@/mixins/snackbar'
  import Loading from '@/components/ui/Loading'
  import { mapGetters } from 'vuex'

  export default {
    components: { Loading },
    mixins: [snackbar],
    data () {
      return {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    },
    computed: {
      ...mapGetters({
        isShowLoading: 'app/getLoading'
      }),
      setHideMenuClass () {
        return window.innerWidth < 1264
      // the screen value 1264 is equal mobile-break-point default of navigation-drawers component. To read more api documentation
      },
      cssVariables () {
        return {
          '--primary': this.$vuetify.theme.themes.light.primary,
          '--secondary': this.$vuetify.theme.themes.light.secondary,
          '--accent': this.$vuetify.theme.themes.light.accent,
          '--action': this.$vuetify.theme.themes.light.action,
          '--error': this.$vuetify.theme.themes.light.error,
          '--info': this.$vuetify.theme.themes.light.info,
          '--success': this.$vuetify.theme.themes.light.success,
          '--warning': this.$vuetify.theme.themes.light.warning
        }
      }
    },
    mounted () {
      this.addListenerSnackbar()
    },
    beforeDestroy () {
      document.removeEventListener('swUpdated', this.updatedAppAlert)
    },
    created () {
      window.addEventListener('online', this.connectionAlert)
      window.addEventListener('offline', this.connectionAlert)

      window.refresApp = this.refresApp
      document.addEventListener('swUpdated', this.updatedAppAlert, { once: true })

      if (navigator && navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return
          this.refreshing = true
          window.location.reload()
        })
      }
    },
    methods: {
      addListenerSnackbar () {
        this.$root.$on('snackbar', data => {
          this.snackbar = { ...this.snackbar, show: false }
          this.$nextTick(() => this.snackbar = { ...this.snackbar, ...data })
        })
      },
      connectionAlert ({ type }) {
        const text = type === 'online' ? 'Conexão estabelecida' : 'Sem conexão'
        const color = type === 'online' ? 'success' : 'error'
        const timeout = type === 'online' ? 5000 : 0

        this.$root.$emit('snackbar', { show: true, color, text, timeout })
      },
      updatedAppAlert (e) {
        this.updateExists = true
        const text = 'Nova versão da aplicação disponível <button class="refresh-page-btn" onClick="javascript: refresApp()">Clique para atualizar</button>'

        this.registration = e.detail
        this.$root.$emit('snackbar', { show: true, color: 'info', text, timeout: 0 })
      },
      refresApp () {
        this.updateExists = false

        if (!this.registration || !this.registration.waiting) { return }

        this.registration.waiting.postMessage('skipWaiting')
      }
    }
  }
</script>

<style lang="sass">
  @import './assets/style/global'

  .v-application
    &.theme--light
      background: $light !important
</style>
