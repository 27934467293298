<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="true"
        class="add-btn mt-5 mr-2"
        :data-cy="dataCy"
        color="accent"
        :to="to"
        fixed
        dark
        bottom
        right
        fab
        v-on="on">
        <v-icon>{{ $root.icons.action.add }}</v-icon>
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
        default: false
      },
      to: {
        type: Object,
        required: true,
        default: () => ({})
      },
      title: {
        type: String,
        required: true,
        default: ''
      },
      dataCy: {
        type: String,
        default: ''
      }
    }
  }
</script>
