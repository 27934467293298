import * as types from './types'
import { processData, processRecords, request } from '@/utils'
import Query from '@/utils/query'
import i18n from '@/plugins/i18n'

export default {
  INIT () {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }, paginate) {
    const db = firebase.firestore()
    const currentCompany = rootGetters['currentCompany/id']
    const userId = rootGetters['user/getUserInfo']['userId']
    const pageSize = paginate.rowsPerPage

    let baseQuery = db.collection('invites').where('userPermissions', 'array-contains', userId).orderBy('updatedAt', 'desc')
    let stats = await db.collection('companies').doc(currentCompany).collection('stats').doc('--invites--').get()

    const query = new Query(baseQuery, commit)
    baseQuery = query.filterByState(paginate.state)
    baseQuery = query.filterByChannel(paginate.channel)
    baseQuery = await query.pageHas(1, paginate.page)
    baseQuery = query.pageNext(paginate.page)
    baseQuery = query.pagePrev(paginate.page)
    baseQuery = baseQuery.limit(pageSize)

    baseQuery.onSnapshot(async snapshots => {
      const length = paginate.state ? stats.data()[paginate.state] || 0 : Object.values(stats.data()).sum(0)
      let records = await processRecords(snapshots)

      records.length && query.paginator.setCurrentPage(paginate.page)
      records.length && query.paginator.setPage(paginate.page, snapshots.docs[0], snapshots.docs[snapshots.docs.length - 1])
      const recordsCache = paginate.page === 1 ? [] : rootGetters['invites/list']

      commit('setLength', length)
      commit('setList', [...recordsCache, ...records])
      commit('setListLoading', false)
      commit('setListRender', true)
    })
  },
  async [types.GET_LIST_BY_CASE] ({ commit, firebase, rootGetters }, id) {
    const db = firebase.firestore()
    const caseRef = db.collection('cases').doc(id)

    db
      .collection('invites')
      .where('case', '==', caseRef)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async documentSnapshots => {
        commit('setListByCase', await processRecords(documentSnapshots))
        commit('setListByCaseLoading', false)
      })
  },
  async get ({ commit, firebase }, id) {
    const db = firebase.firestore()

    db
      .collection('invites')
      .doc(id)
      .onSnapshot(async doc => {
        const timeline = await doc.ref.collection('timeline').orderBy('insertedAt', 'asc').get()
        const timelineData = await processRecords(timeline)
        doc = await processData(doc)
        timelineData.map(item => {
          item.date = item.insertedAt && i18n.d(item.insertedAt.toDate(), 'date')
          item.time = item.insertedAt && i18n.d(item.insertedAt.toDate(), 'time')
        })

        doc.timeline = timelineData.groupBy(i => i.date)
        commit('record', doc)
        commit('recordLoading', false)
      })
  },
  async [types.RESEND] ({ firebase }, id) {
    return firebase.functions().httpsCallable('inviteResend')({ id })
  },
  async [types.ADD] ({ firebase }, {caseId, email}) {
    return firebase.functions().httpsCallable('inviteAdd')({ caseId, email })
  }
}
