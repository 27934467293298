import * as types from './types'
import Vue from 'vue'

export default {
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_SESSION] (state, session) {
    Vue.set(state, 'session', session)
  },
  [types.SET_INSTANCE] (state, instance) {
    Vue.set(state, 'instance', instance)
  },
  [types.ADD_PARTICIPANT] (state, { userInfo, pubSub }) {
    state.subscribers.push({...userInfo, pubSub})
  },
  [types.REMOVE_PARTICIPANT] (state, event) {
    state.subscribers = state.subscribers.filter(sub => {
      return sub.pubSub.stream !== event.stream
    })
  },
  [types.DESTROY_SESSION] (state) {
    if (state.session) {
      state.session.disconnect()
      Vue.set(state, 'session', null)
      Vue.set(state, 'instance', null)
      Vue.set(state, 'subscribers', [])
    }
  }
}
