export default {
  length (state) {
    return state.length
  },
  list (state) {
    return state.list
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  listRender (state) {
    return state.listRender
  }
}
