import * as types from './types'
import { processRecords } from '@/utils'
import i18n from '@/plugins/i18n'

export default {
  INIT ({ commit, firebase }) {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }, state) {
    const currentCompany = rootGetters['currentCompany/id']
    const db = firebase.firestore()
    const companyRef = db.collection('companies').doc(currentCompany)

    db
      .collection('batches')
      .where('company', '==', companyRef)
      .orderBy('insertedAt', 'asc')
      .onSnapshot(async documentSnapshots => {
        const records = await processRecords(documentSnapshots)

        // records.map(item => {
        //   item.dueAt = i18n.d(item.dueAt.toDate(), 'date')
        //   return item
        // })

        commit('setList', records)
        commit('setListLoading', false)
        commit('setListRender', true)
      })
  },
  async [types.IMPORT] ({}, data) {
    return firebase.functions().httpsCallable('batchImport')(data)
  }
}
