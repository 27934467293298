import Vue from 'vue'
import InputNumber from '@/components/ui/inputs/Number'
import BtnFabCreate from'@/components/ui/BtnFabCreate'
import ListItem from'@/components/ui/ListItem'
import SideContent from'@/components/ui/SideContent'

Vue.component('v-number-field', InputNumber)
Vue.component('btn-fab-create', BtnFabCreate)
Vue.component('list-item', ListItem)
Vue.component('side-content', SideContent)
