import * as types from './types'
import { USER_ID, AUTH_TOKEN, GROUPS } from '@/utils/index'

const mutations = {
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
  'SET_USER' (state, payload) {
    state.user = payload
  },
  'setToken' (state, payload) {
    localStorage.setItem(AUTH_TOKEN, payload)
    state.user.token = payload
  },
  'setUserId' (state, payload) {
    localStorage.setItem(USER_ID, payload)
    state.user.userId = payload
  },
  'setEmail' (state, payload) {
    state.user.email = payload
  },
  'setPhotoURL' (state, payload) {
    state.user.photoURL = payload
  },
  'setGroups' (state, payload) {
    localStorage.setItem(GROUPS, JSON.stringify(payload))
    state.groups = payload
  },
  'setName' (state, payload) {
    state.user.name = payload
  },
  'setDocument' (state, payload) {
    state.user.document = payload
  },
  'setBirthDate' (state, payload) {
    if(payload) {
      const date = payload.toDate ? payload.toDate() : payload
      const birthDate = new Date(date).toLocaleDateString('pt-BR', {year: 'numeric', month: 'numeric', day: 'numeric' })
      state.user.birthDate = birthDate
    }
  },
  'RESET' (state) {
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(USER_ID)
    localStorage.removeItem(GROUPS)
    const newState = {
      isLoggedIn: false,
      groups: [],
      user: {
        currentCompany: null,
        name: null,
        email: null,
        token: null
      }
    }
    Object.keys(newState).forEach(key => { state[key] = newState[key] })
  }
}

export default mutations
