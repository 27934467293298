import * as types from './types'
import { processData, processRecords} from '@/utils'
import Query from '@/utils/query'

export default {
  INIT () {},
  async [types.GET_LIST] ({ commit, firebase, rootGetters }, paginate) {
    const userId = rootGetters['user/getUserInfo']['userId']
    const page = paginate.page || 1
    let baseQuery = firebase.firestore().collection('companies').where('userPermissions', 'array-contains', userId).orderBy('insertedAt', 'desc')
    const query = new Query(baseQuery, commit)
    baseQuery = await query.pageHas(1, page)
    baseQuery = query.pageNext(page)
    baseQuery = query.pagePrev(page)
    if(paginate.rowsPerPage > 0){
      baseQuery = baseQuery.limit(paginate.rowsPerPage)
    }
    baseQuery.onSnapshot(async snapshots => {
      const records = await processRecords(snapshots)

      records.length && query.paginator.setCurrentPage(page)
      records.length && query.paginator.setPage(page, snapshots.docs[0], snapshots.docs[snapshots.docs.length - 1])

      const collection = await firebase.firestore().collection('companies').where('userPermissions', 'array-contains', userId).get()
      commit('totalCount', collection.size)
      commit('setList', records)
      commit('setListLoading', false)
    })
  },
  async [types.GET] ({ commit, firebase }, id) {
    firebase
      .firestore()
      .collection('companies')
      .doc(id)
      .onSnapshot(async doc => {
        const record = await processData(doc)
        const responsible = await record.responsible
        record.responsible = responsible && responsible.data ? responsible.data() : {}
        commit('set', record)
        commit('setRecordLoading', false)
      })
  },

  async [types.CREATE] ({ firebase }, data) {
    return firebase.functions().httpsCallable('companyCreate')(data)
  },

  async [types.UPDATE] ({ commit, firebase }, {companyId, companyParams}) {
    commit('setRecordLoading', true)
    const record = await firebase.firestore().collection('companies').doc(companyId).update(companyParams)
    commit('setRecordLoading', false)
    return record
  },

  async [types.CHANGE_PAGARME] ({ commit, firebase }, data) {
    commit('app/setLoading', true, { root: true })
    return firebase.functions().httpsCallable('companyPagarme')(data).finally(_ =>
      commit('app/setLoading', false, { root: true })
    )
  },

  async [types.CHANGE_LOGO]({ commit, firebase }, { companyId, companyLogo }) {
    try {
      commit('app/setLoading', true, { root: true })
      const storageRef = firebase.storage().ref()
      const fileParts = companyLogo.name.split('.')
      const extension = fileParts[fileParts.length - 1]
      const path = `companies/${companyId}/public/logo.${extension}`
      const snapshot = await storageRef.child(path).put(companyLogo, { contentType: companyLogo.type, public: true })
      const downloadURL = await snapshot.ref.getDownloadURL()
      const imageUrl = `${downloadURL.split('?')[0]}?alt=media&time=${new Date().getTime()}`
      const data = { logo: imageUrl }
      return await firebase.firestore().collection('companies').doc(companyId).update(data)
    } catch (error) {
      throw error
    } finally {
      commit('app/setLoading', false, { root: true })
    }
  }
}
