import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales'

const dateTimeFormats = {
  'en-US': {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    short_date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    short_datetime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }
  },
  'pt-BR': {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    short_date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    short_datetime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }
  }
}

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  },
  'pt-BR': {
    currency: {
      style: 'currency', currency: 'BRL'
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'pt-BR',
  numberFormats,
  dateTimeFormats,
  messages: locales
})
