import * as types from './types'
import { set } from '@/utils/vuex'
import Vue from 'vue'

export default {
  companyName: set('companyName'),
  camaraName: set('camaraName'),
  guestName: set('guestName'),
  [types.SET_LENGTH] (state, payload) {
    state.length = payload
  },
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
  [types.LOADING] (state, payload) {
    state.loading = payload
  },
  [types.SET_LIST_RENDER] (state, payload) {
    state.listRender = payload
  },
  [types.SET] (state, payload) {
    state.record = payload
  },
  [types.SET_RECORD_LOADING] (state, payload) {
    state.recordLoading = payload
  },
  [types.SET_PARTICIPANTS] (state, payload) {
    state.participants = payload
  },
  [types.SET_PARTICIPANTS_LOADING] (state, payload) {
    state.participantsLoading = payload
  },
  [types.SET_PAGE] (state, payload) {
    state.page = payload
  },
  [types.SET_BACKWARD] (state, payload) {
    state.backward = payload
  },
  [types.SET_FORWARD] (state, payload) {
    state.forward = payload
  },
  [types.SET_TIMELINE] (state, payload) {
    state.timeline = payload
  },
  [types.SET_TIMELINE_LOADING] (state, payload) {
    state.timelineLoading = payload
  },
  [types.SET_MESSAGES] (state, payload) {
    state.messages = payload
  },
  [types.SET_MESSAGES_LOADING] (state, payload) {
    state.messagesLoading = payload
  },
  [types.SET_DOCUMENTS] (state, payload) {
    state.documents = payload
  },
  [types.SET_DOCUMENTS_LOADING] (state, payload) {
    state.documentsLoading = payload
  },
  [types.SET_NOTES] (state, payload) {
    state.notes = payload
  },
  [types.SET_NOTES_LOADING] (state, payload) {
    state.notesLoading = payload
  },
  [types.SET_TASKS] (state, payload) {
    state.tasks = payload
  },
  [types.SET_TASKS_LOADING] (state, payload) {
    state.tasksLoading = payload
  },
  [types.SET_REPORT] (state, payload) {
    state.report = payload
  }
}
