// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
/* globals: location */
import Vue from 'vue'
import App from './App'

import '@/plugins/extend'
import '@/plugins/intersect'
import '@/plugins/commons'
import '@/plugins/money'
import '@/plugins/shortKey'
import '@/plugins/anime'
import '@/plugins/perfect-scrollbar'
import '@/plugins/async-computed'
import '@/plugins/can'
import '@/plugins/uuid'
import '@/plugins/components'
import '@/plugins/font-awesome'
import '@/plugins/vue-the-mask'
import '@/plugins/wysiwyg'
import '@/plugins/vue-timeago'
import '@/plugins/vjsf'
import './registerServiceWorker'

import vuetify from '@/plugins/vuetify'
import router from '@/router'
import { store } from '@/store'
import i18n from '@/plugins/i18n'
import { icons } from '@/utils/icons'
// import Raven from 'raven-js'
// import RavenVue from 'raven-js/plugins/vue'
import { Firebase } from '@/plugins/firebase'

// Raven
//   .config('https://64130e602b0942fba63e51115ae9b020@sentry.io/1206376')
//   .addPlugin(RavenVue, Vue)
//   .install()

Vue.config.productionTip = false

function vueSingleton() {
  if (!vueSingleton.instance) {
    vueSingleton.instance = new Vue({
      el: '#app',
      router,
      store,
      i18n,
      vuetify,
      components: { App },
      data: () => ({ icons }),
      template: '<App/>'
    })
  }

  return vueSingleton.instance
}

Firebase.auth().onAuthStateChanged((_firebaseUser) => {
  const db = Firebase.database()

  const firebaseMixin = (Vue) => {
    Vue.mixin({
      data() {
        return {
          firebase: {
            db: db,
            firestore: Firebase.firestore,
            storage: Firebase.storage
          }
        }
      }
    })
  }

  Vue.use(firebaseMixin)

  vueSingleton()
})
