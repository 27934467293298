export const GET_LIST = 'getList'
export const SET_LIST = 'setList'
export const SET_LIST_LOADING = 'setListLoading'
export const SET_LIST_RENDER = 'setListRender'
export const GET = 'get'
export const SET = 'set'
export const UNSET = 'unset'
export const SET_RECORD_LOADING = 'setRecordLoading'
export const CREATE = 'create'
export const UPDATE = 'update'
export const TOTAL_COUNT = 'totalCount'
export const CHANGE_LOGO = 'changeLogo'
export const CHANGE_PAGARME = 'changePagarme'
