export const GET_LIST = 'getList'
export const SET_LIST = 'setList'
export const SET_LIST_LOADING = 'setListLoading'
export const SET_LIST_RENDER = 'setListRender'
export const LOADING = 'loading'
// Record
export const GET = 'get'
export const SET = 'set'
export const UNSET = 'unset'
export const SET_RECORD_LOADING = 'setRecordLoading'
// Create
export const CREATE = 'create'
// Participants
export const GET_PARTICIPANTS = 'getParticipants'
export const SET_PARTICIPANTS = 'setParticipants'
export const SET_PARTICIPANTS_LOADING = 'setParticipantsLoading'
// Paginate
export const SET_PAGE = 'setPage'
export const SET_FORWARD = 'setForward'
export const SET_BACKWARD = 'setBackward'
// Timeline
export const GET_TIMELINE = 'getTimeline'
export const SET_TIMELINE = 'setTimeline'
export const SET_TIMELINE_LOADING = 'setTimelineLoading'
// Messages
export const GET_MESSAGES = 'getMessages'
export const SET_MESSAGES = 'setMessages'
export const SET_MESSAGES_LOADING = 'setMessagesLoading'
export const SEND_MESSAGE = 'sendMessage'
// Notes
export const GET_NOTES = 'getNotes'
export const SET_NOTES = 'setNotes'
export const SET_NOTES_LOADING = 'setNotesLoading'
export const CREATE_NOTE = 'createNote'
// Documents
export const GET_DOCUMENTS = 'getDocuments'
export const SET_DOCUMENTS = 'setDocuments'
export const SET_DOCUMENTS_LOADING = 'setDocumentsLoading'
// Tasks
export const GET_TASKS = 'getTasks'
export const SET_TASKS = 'setTasks'
export const SET_TASKS_LOADING = 'setTasksLoading'
export const CREATE_TASK = 'createTask'
export const VIDEOCONFERENCE_TOKEN = 'videoConferenceToken'
export const VIDEOCONFERENCE_START = 'videoConferenceStart'
export const VIDEOCONFERENCE_COMPLETE = 'videoConferenceComplete'
export const VIDEOCONFERENCE_IN_PROGRESS = 'videoConferenceInProgress'
// Report
export const GET_REPORT = 'getReport'
export const SET_REPORT = 'setReport'
// Import
export const IMPORT = 'import'

export const UPDATE_TICKET = 'updateTicket'
export const ADD_TICKETS = 'addTickets'
export const REMOVE_TICKET = 'removeTicket'
export const UPDATE_RECIPIENT_EMAIL = 'updateRecipientEmail'
export const UPDATE_RECIPIENT_PHONES = 'updateRecipientPhones'
export const UPDATE_RECIPIENT_ADDRESS = 'updateRecipientAddress'
export const SET_LENGTH = 'setLength'
export const DROP = 'drop'
