export default {
  length: 0,
  list: [],
  listLoading: true,
  listRender: false,
  record: {},
  simulated_renegotiation: null,
  rangeValue: {},
  range: [],
  parcels: null,
  firstActive: {},
  firstActiveForCase: {},
  lastForCase: {},
  firstAccepted: {},
  firstActiveLoading: true,
  listByCase: [],
  listByCaseLoading: true,
  slider: {
    value: 1,
    min: 1,
    max: 1,
    data: [],
    range: []
  }
}
