const getters = {
  list (state) {
    return state.list
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  getGroups (state) {
    return (state.groups || [])
  },
  canLoadCompanies (state) {
    const groups = (state.groups || [])
    return groups.includes('superuser') || groups.includes('administrator')
  },
  isAdministrator (state) {
    return ((state.groups || []) || []).includes('administrator')
  },
  isGuest (state) {
    return (state.groups || []).includes('guest')
  },
  isNegotiator (state) {
    return (state.groups || []).includes('negotiator')
  },
  isMediator (state) {
    return (state.groups || []).includes('mediator')
  },
  getUserInfo (state) {
    return state.user
  },
  id (state) {
    return state.user.userId
  },
  getToken (state) {
    return state.user.token
  }
}

export default getters
