export const GET_DASHBOARD_PANEL_RESULT = 'getDashboardPanelResult'
export const SET_DASHBOARD_PANEL_RESULT = 'setDasboardPanelResult'
export const GET_DASHBOARD_ACCEPTED = 'getDashboardAccepted'
export const SET_DASHBOARD_ACCEPTED = 'setDasboardAccepted'
export const GET_DASHBOARD_CASES_COUNT = 'getDashboardCasesCount'
export const SET_DASHBOARD_CASES_COUNT = 'setDasboardCasesCount'
export const GET_DASHBOARD_INVITES = 'getDashboardInvites'
export const SET_DASHBOARD_INVITES = 'setDasboardInvites'
export const GET_INVITES_COUNTS_BY_CHANNEL = 'getInvitesCountsByChannel'
export const GET_INVITES_COUNTS_ACCESS_BY_CHANNEL = 'getInvitesCountsAccessByChannel'
export const GET_INVITES_OPEN_VS_CLICK = 'getInvitesOpenVsClick'
