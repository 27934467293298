import * as types from './types'
import Vue from 'vue'

export default {
  [types.SET_LIST] (state, payload) {
    state.list = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
  [types.SET_LIST_RENDER] (state, payload) {
    state.listRender = payload
  },
  [types.SET_PAGE] (state, payload) {
    state.page = payload
  },
  [types.SET_START_AT] (state, payload) {
    state.startAt = payload
  },
  [types.SET_START_AFTER] (state, payload) {
    state.startAfter = payload
  }
}
