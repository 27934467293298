export default {
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  listLoading (state) {
    return state.listLoading ? 1 : 0
  },
  record (state) {
    return state.record
  },
  recordLoading (state) {
    return state.recordLoading ? 1 : 0
  }
}
