import { set } from '@/utils/vuex'

const mutations = {
  setSettings: set('settings'),
  setHosts: set('hosts'),
  setTheme: set('theme'),
  setLoading: set('isLoading'),
  setIsAuthenticated: set('isAuthenticated'),
  setAbilities: set('abilities'),
  setCompanyAbilities: set('companyAbilities'),
  videoConference (state, { room, show }) {
    state.videoConferenceShow = show
    state.videoConferenceRoom = room
  }
}

export default mutations
