import Paginator from '@/utils/paginator'
import md5 from 'js-md5'

export default class Query {
  constructor (base, commit) {
    const md5Id = md5(base.toString())
    if(Query.queries && Query.queries[md5Id]) {
      Query.queries[md5Id].base = base
      return Query.queries[md5Id]
    }

    Query.queries = {}
    Query.queries[md5Id] = this

    this.base = base
    this.paginator = new Paginator()
    this.commit = commit

    return this
  }

  filterByState (state) {
    // if (state && state.length > 0) {
    //   this.commit('setList', [])
    //   this.commit('setListLoading', true)
    //   this.commit('setListRender', false)
    //   // // this.paginator.reset()
    // }

    const isArray = Array.isArray(state)

    if (isArray && state.length > 0) {
      this.base = this.base.where('state', 'in', state)
    } else if (!isArray && state) {
      this.base = this.base.where('state', '==', state)
    }

    return this.base
  }

  filterByChannel (channel) {
    const isArray = Array.isArray(channel)

    if (isArray && channel.length > 0) {
      this.base = this.base.where('channel', 'in', channel)
    } else if (!isArray && channel) {
      this.base = this.base.where('channel', '==', channel)
    }

    return this.base
  }

  async pageHas (index, currentPage) {
    this.paginator.setQuery(this.base)
    return await this.paginator.has(1, currentPage)
  }

  pageNext (page) {
    return this.paginator.lt(page)
  }

  pagePrev (page) {
    return this.paginator.gt(page)
  }
}
