import * as types from './types'

export default {
  [types.SET_QUESTIONS] (state, payload) {
    state.questions = payload
  },
  [types.SET_LIST_LOADING] (state, payload) {
    state.listLoading = payload
  },
}
