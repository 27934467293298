import * as types from './types'
import { processRecords } from '@/utils'

export default {
  INIT({ }) { },
  async [types.GET_LIST]({ commit, firebase, rootGetters }) {
    const userId = rootGetters['user/getUserInfo']['userId']
    const userRef = firebase.firestore().collection('users').doc(userId)
    return firebase
      .firestore()
      .collection('notifications')
      .where('unread', '==', true)
      .where('user', '==', userRef)
      .orderBy('insertedAt', 'desc')
      .onSnapshot(async querySnapshot => {
        let records = await processRecords(querySnapshot)
        records.map(item => {
          item.insertedAtDate = item.insertedAt.toDate()
          item.from =  new Promise(async resolve => {
            const caseSnapshot = await item.case
            const caseData = caseSnapshot.data()
            return resolve(caseData.user.get())
          })
          return item
        })
        commit('setList', records)
        commit('setListLoading', false)
      })
  },
  async [types.READ]({ firebase, rootGetters }, caseId) {
    const userId = rootGetters['user/getUserInfo']['userId']
    const userRef = firebase.firestore().collection('users').doc(userId)
    const caseRef = firebase.firestore().collection('cases').doc(caseId)
    const records = await firebase
      .firestore()
      .collection('notifications')
      .where('unread', '==', true)
      .where('user', '==', userRef)
      .where('case', '==', caseRef)
      .orderBy('insertedAt', 'desc')
      .get()
    records.docs.forEach(async snapshot => {
      snapshot.ref.update({ unread: false, updatedAt: new Date() })
    })
  }
}
