import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '@firebase/app'
import '@firebase/auth'
import '@firebase/database'
import '@firebase/firestore'
import '@firebase/storage'
import '@firebase/performance'
import '@firebase/functions'
import '@firebase/analytics'

const isLocal = hostname => {
  return hostname.startsWith('192.168.') ? true : false
}

let firebaseProd = {
  apiKey: 'AIzaSyDVj4FvtSimgfWEU03sjwgUtj-lpstsE5w',
  authDomain: 'mais-210502.firebaseapp.com',
  databaseURL: 'https://mais-210502.firebaseio.com',
  projectId: 'mais-210502',
  storageBucket: 'mais-210502.appspot.com',
  messagingSenderId: '782392286682',
  appId: '1:782392286682:web:aaafa63efd09f941',
  measurementId: 'G-7S8GE1W9B1'
}

let firebaseStaging = {
  apiKey: 'AIzaSyA6FWMRFctXinpOCFYlIiJrhYLAc5UK_Ps',
  authDomain: 'mais-staging.firebaseapp.com',
  databaseURL: 'https://mais-staging.firebaseio.com',
  projectId: 'mais-staging',
  storageBucket: 'mais-staging.appspot.com',
  messagingSenderId: '712173554183',
  appId: '1:712173554183:web:0ababcafbaa8b72b',
  measurementId: 'G-LMPGF3DF0F'
}

if(isLocal(location.hostname) || location.hostname === 'localhost') {
  console.debug('Initialize firebase staging')
  firebase.initializeApp(firebaseStaging)
firebase.functions().useFunctionsEmulator(`http://${location.hostname}:5001`)
} else if (location.hostname === 'staging.modera.app') {
  console.debug('Initialize firebase staging')
  firebase.initializeApp(firebaseStaging)
} else {
  console.debug('Initialize firebase production')
  firebase.initializeApp(firebaseProd)
}

firebase.analytics()

const perf = firebase.performance()

Vue.prototype.firebase = firebase

function isPromise (val) {
  return val && typeof val.then === 'function'
}

function patchAction (store, type, handler, local) {
  const entry = store._actions[type] || (store._actions[type] = [])

  if (entry.length > 0) entry.pop()
  entry.push(function wrappedActionHandler (payload, cb) {
    let res = handler({
      dispatch: local.dispatch,
      commit: local.commit,
      getters: local.getters,
      state: local.state,
      rootGetters: store.getters,
      rootState: store.state,
      firebase: firebase,
      perf: perf
    }, payload, cb)

    if (!isPromise(res)) res = Promise.resolve(res)
    if (store._devtoolHook) {
      return res.catch(err => {
        store._devtoolHook.emit('vuex:error', err)
        throw err
      })
    } else {
      return res
    }
  })
}

function patchModule (store, path, module, hot) {
  const namespace = store._modules.getNamespace(path)
  const local = module.context

  module.forEachAction((action, key) => patchAction(store, (namespace + key), action, local))
  module.forEachChild((child, key) => patchModule(store, path.concat(key), child, hot))
}

export const Firebase = firebase

export default store => {
  patchModule(store, [], store._modules.root)

  const orig = Vuex.Store.prototype.registerModule
  Vuex.Store.prototype.registerModule = function registerModule (path, rawModule) {
    orig.call(this, path, rawModule)
    patchModule(this, [].concat(path), this._modules.get([path]))
    this.dispatch(`${path}/INIT`)
  }
}
