import Vue from 'vue'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'

const options = {
  hideModules: { 
    'bold': true,
    'italic': true,
    'underline': true,
    'justifyLeft': true,
    'justifyCenter': true,
    'justifyRight': true,
    'headings': true,
    'link': true,
    'code': true,
    'orderedList': true,
    'unorderedList': true,
    'image': true,
    'table': true,
    'removeFormat': true,
    'separator': true
  },
  forcePlainTextOnPaste: true
}

Vue.use(wysiwyg, options)