const state = {
  isLoading: false,
  isAuthenticated: false,
  settings: {},
  hosts: {},
  theme: {},
  abilities: [],
  videoConferenceShow: false,
  videoConferenceRoom: null
}

export default state
