import { RequireAutorization } from './routerAuth'

export const ROUTE_FINANCIAL = {
  path: '/financeiro',
  component: () => import(/* webpackChunkName: "FinancialRoute" */'@/components/Financial/Financial'),
  beforeEnter: RequireAutorization,
  children: [
    {
      name: 'FinancialDashboard',
      path: '',
      component: () => import(/* webpackChunkName: "FinancialRoute" */'@/components/Financial/Dashboard'),
      meta: {title: 'Dashboard Financeiro'},
      beforeEnter: RequireAutorization
    },
    {
      path: 'boletos',
      component: () => import(/* webpackChunkName: "FinancialBankSlipRoute" */'@/components/BankSlip/Layout'),
      meta: {title: 'Boletos'},
      beforeEnter: RequireAutorization,
      children: [
        {
          name: 'BankSlip',
          path: '',
          component: () => import(/* webpackChunkName: "FinancialBankSlipRoute" */'@/components/BankSlip/List'),
          meta: {title: 'Boletos Emitidos'},
          beforeEnter: RequireAutorization
        },
        {
          name: 'BankSlipReturn',
          path: 'retorno',
          component: () => import(/* webpackChunkName: "FinancialBankSlipReturn" */'@/components/BankSlip/Return'),
          meta: {title: 'Arquivo de Retorno'},
          beforeEnter: RequireAutorization
        }
      ]
    }
  ]
}
