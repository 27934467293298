import * as types from './types'
import { processRecords } from '@/utils'

const statMapping = [ 'bad', 'fair', 'good', 'veryGood', 'excellent' ]

export default {
  INIT ({}) {},

  async [types.GET_QUESTIONS] ({ commit, firebase }, surveyKind) {
    const db = firebase.firestore()
    let questions
    if (surveyKind) {
      questions = db
        .collection('questions')
        .where('type', 'array-contains', surveyKind)
        .orderBy('order', 'asc')
    } else {
      questions = db.collection('questions')
    }

    questions
      .onSnapshot(async documentSnapshots => {
        const records = await processRecords(documentSnapshots)
        commit('setQuestions', records)
        commit('setListLoading', false)
      })
  },

  async [types.SUBMIT_SURVEY] ({ commit, firebase, rootGetters }, { surveyResults, companyId, answerId, proposalId = null }) {
    const db = firebase.firestore()
    const batch = db.batch()
    const userId = rootGetters['user/getUserInfo']['userId']
    commit('app/setLoading', true, { root: true })
    const proposalRef = proposalId ? db.collection('proposals').doc(proposalId) : null
    const companyRef = db.collection('companies').doc(companyId)
    const userRef = db.collection('users').doc(userId)
    try {
      for (const questionId in surveyResults) {
        const questionRef = db.collection('questions').doc(questionId)
        const index = surveyResults[questionId] - 1
        const ratingLabel = statMapping[index]
        const question = await questionRef.get()
        const stats = question.data().stats
        const value = (stats[ratingLabel] || 0) + 1
        const answerRef = questionRef.collection('answers').doc(answerId)
        const data = { company: companyRef, user: userRef, answer: ratingLabel, insertedAt: new Date() }
        if (proposalRef) {
          data.proposal = proposalRef
        }
        batch.set(answerRef, data)
        batch.update(questionRef, { stats: { ...stats, [ratingLabel]: value } })
      }
      if (proposalRef) {
        batch.update(proposalRef, { surveyAnswered: true })
      }
      return batch.commit()
    } catch (error) {
      throw error
    } finally {
      commit('app/setLoading', false, { root: true })
    }
  }
}
