<template>
  <div>
    <v-list-item :class="className">
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold caption">
          {{ label }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="my-1">
        <v-list-item-title class="caption">
          {{ value }}
          <v-tooltip
            v-if="info"
            right
            allow-overflow>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                v-on="on">
                <v-icon small color="primary">
                  mdi-alert-circle
                </v-icon>
              </v-btn>
            </template>
            {{ info }}
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-action>
    </v-list-item>
    <v-divider v-if="divider" />
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number, Boolean],
        default: ''
      },
      info: {
        type: [String, Number],
        default: ''
      },
      className: {
        type: String,
        default: 'px-2'
      },
      divider: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  .v-list-item--dense, .v-list--dense .v-list-item
    min-height: 34px
</style>
