export const GET_LIST = 'getList'
export const SET_LIST = 'setList'
export const SET_LENGTH = 'setLength'
export const GET_ACTIVE_LIST = 'getActiveList'
export const SET_LIST_LOADING = 'setListLoading'
export const SET_LIST_RENDER = 'setListRender'
export const GET_PROPOSAL = 'get'
export const SET_PROPOSAL = 'setProposal'
export const GET_SIMULATED_RENEGOTIATION = 'GET_SIMULATED_RENEGOTIATION'
export const PROPOSAL_CHANGE_PLOT = 'PROPOSAL_CHANGE_PLOT'
export const FIRST_ACTIVE = 'firstActive'
export const LAST_FOR_CASE = 'lastForCase'
export const FIRST_ACTIVE_FOR_CASE = 'firstActiveForCase'
export const FIRST_ACTIVE_LOADING = 'firstActiveLoading'
export const GET_LIST_BY_CASE = 'getListByCase'
export const SET_LIST_BY_CASE = 'setListByCase'
export const SET_LIST_BY_CASE_LOADING = 'setListByCaseLoading'
export const GET_BANK_SLIPS = 'getBankSlips'
export const ACCEPT = 'accept'
export const REFUSE = 'refuse'
export const FINISH = 'finish'
export const NEGOTIATE = 'negotiate'
export const NEGOTIATION_ACCEPT = 'negotiationAccept'
export const NEGOTIATION_REFUSE = 'negotiationRefuse'
export const CALCULATE = 'calculate'
export const CREATE = 'create'
export const DIRECT_NEGOTIATION_ACCEPT = 'directNegotiationAccept'
export const DIRECT_NEGOTIATION_REFUSE = 'directNegotiationRefuse'
export const MOVE_TO_NEGOTIATION = 'moveToNegotiation'
