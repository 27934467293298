import { RequireAutorization } from './routerAuth'

export const ROUTE_CENTRAL_CHANNELS =
  {
    path: '/central',
    component: () => import(/* webpackChunkName: "Central" */'@/components/pages/Central'),
    meta: { title: 'Central de Comunicações' },
    children: [
      {
        path: 'cartas',
        name: 'CentraLetters',
        component: () => import(/* webpackChunkName: "Central" */'@/components/channels/ListLetters'),
        children: [
          {
            path: ':inviteId',
            name: 'CentralLetterShow',
            component: () => import(/* webpackChunkName: "Central" */'@/components/channels/Show'),
            meta: { title: 'Convite', routeClose: 'CentraLetters' }
          },
        ]
      },
      {
        path: 'emails',
        name: 'CentralEmails',
        component: () => import(/* webpackChunkName: "Central" */'@/components/channels/ListEmails'),
        children: [
          {
            path: ':inviteId',
            name: 'CentralEmailShow',
            component: () => import(/* webpackChunkName: "Central" */'@/components/channels/Show'),
            meta: { title: 'Convite', routeClose: 'CentralEmails' }
          },
        ]
      },
      {
        path: '/',
        name: 'Central',
        component: () => import(/* webpackChunkName: "Central" */'@/components/channels/ListAll'),
        children: [
          {
            path: ':inviteId',
            name: 'CentralShow',
            component: () => import(/* webpackChunkName: "Central" */'@/components/channels/Show'),
            meta: { title: 'Convite', routeClose: 'Central' }
          },
        ]
      },
      {
        path: '*',
        name: 'CentralError',
        component: () => import(/* webpackChunkName: "Central" */'@/components/ui/ContextNotFound'),
        meta: {title: 'Essa página não existe'}
      }
    ],
    beforeEnter: RequireAutorization
  }
