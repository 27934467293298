import { get } from '@/utils/vuex'

export default {
  getLogo (state) {
    return state.settings.logo
  },
  getMiniLogo (state) {
    return state.settings.mini_logo
  },
  getHostApi (state) {
    return state.hosts.api
  },
  getHostUpload (state) {
    return state.hosts.upload
  },
  getTheme: get('theme'),
  getLoading: get('isLoading'),
  companyAbilities: get('companyAbilities'),
  abilities: get('abilities'),
  videoConferenceShow: get('videoConferenceShow'),
  videoConferenceRoom: get('videoConferenceRoom')
}
